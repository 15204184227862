import React from 'react'
import styles from "../styles/duka.module.css"
import ParanomaG1 from './ParanomaG1'
import ParanomaG2 from './ParanomaG2'
import ParanomaG3 from './ParanomaG3'
import ParanomaG4 from './ParanomaG4'
import ParanomaG5 from './ParanomaG5'
import ParanomaGz1 from './ParanomaGz1'
import ParanomaGz3 from './ParanomaGz3'
import ParanomaGz4 from './ParanomaGz4'
import ParanomaGz5 from './ParanomaGz5'
import ParanomaGz2 from './ParanomaGz2'

function Godown2() {
  return (
    <div className={styles.duka}>
        <ParanomaGz1/>
        <ParanomaGz2/>
        <ParanomaGz3/>
        <ParanomaGz4/>
        <ParanomaGz5/>
    </div>
  )
}

export default Godown2