import React from 'react'
import Head from '../components/Head'
import Advertise from '../components/Advertise'
import Footer from '../components/Footer'
import AliceCaru from '../components/AliceCaru'



function Adverts() {
  return (
    <>
      <head>
      <meta name="description" content="Tangaza na zero-dalali , fikisha ujumbe wako kwa watu wengi zaidi" />
    <link rel="icon" href="./dalali-icon.png"  />
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9205716940827637"
     crossorigin="anonymous"></script>
    <title>Tangaza nasi</title>
      </head>
    <Head/>
    <Advertise/>
    <AliceCaru/>
    <Footer/>
    </>
  )
}

export default Adverts