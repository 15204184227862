import { makeStyles } from "@material-ui/core";


export default makeStyles((theme) => ({
    icon: {
        color: 'white',
        fontSize: '1.5vw',
        [theme.breakpoints.down('sm')]: { fontSize: "3.5vw"}
    },

    iconp: {
        color: 'white',
        fontSize: '2vw',
        [theme.breakpoints.down('sm')]: { fontSize: "5vw"}
    },

    rate: {
        marginTop: "50%",
        fontSize: '2vw',
        [theme.breakpoints.down('sm')]: { fontSize: "4vw", marginTop: "300%"}
    },

    rate2: {    
        fontSize: '2vw',
        [theme.breakpoints.down('sm')]: { fontSize: "4vw"}
    },

    icon3: {
        color: 'white',
        fontSize: '2vw',
        [theme.breakpoints.down('sm')]: { fontSize: "3.5vw"}
    },
    icon4: {
        color: 'white',
        fontSize: '2.5vw',
        cursor: "pointer",
        [theme.breakpoints.down('sm')]: { fontSize: "6vw"}
    },
    delete: {
        color: 'red',
        fontSize: '2vw',
        [theme.breakpoints.down('sm')]: { fontSize: "4vw"}
    },
    edits: {
        color: 'white',
        fontSize: '2vw',
        [theme.breakpoints.down('sm')]: { fontSize: "4vw"}
    },
      
    iconx: {
        color: 'red',
        fontSize: '1.5vw',
        [theme.breakpoints.down('sm')]: { fontSize: "3.5vw"}
    },
    watsap: {
        color: 'white',
        fontSize: '6vw',
        cursor: "pointer",
        [theme.breakpoints.down('sm')]: { fontSize: "20vw"}
    },
    
}))