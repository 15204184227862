import React, { useEffect, useState} from 'react';
import styles from "../styles/alice.module.css"
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import house from "../images/house.png"
import cafe from "../images/Cafe-Shop-PNG-Image.png"
import warehouse from "../images/warehouse.png"
import advert from "../images/advertize.png"
import office from "../images/big office.png"
import plot from "../images/Land_plot.png"
import desk from "../images/office-desk-t91.png"
import log from "../images/zerodalali logo2.png"



const handleOnDragStart = e => e.preventDefault();

const AliceCaru = ({ media_type, id }) => {
    const credits = [
        {
            picha: house,
            maneno: "Nyumba za kila aina zinapatikana",
        },
        {
            picha: plot,
            maneno: "Viwanja vya nyumba tele vinapatikana",
        },
        {
            picha: office,
            maneno: "Majengo ya kibiashara yanapangishwa",
        },
        {
            picha: desk,
            maneno: "Ofisi mbalimbali zinakodishwa",
        },
        {
            picha: cafe,
            maneno: "Mgahawa na maduka inapangishwa",
        },
        {
            picha: warehouse,
            maneno: "Go-down na ghala zinapangishwa za kutosha",
        },
        {
            picha: log,
            maneno: "Karibu Zero-Dalali Ujipatie",
        },
        {
            picha: advert,
            maneno: "Kama unataka kutangaza hapa nenda 'Tangaza nasi'",
        },
    ]
          
    const items = credits?.map((c) => (
        <div className={styles.card} >
            <img
                src={c.picha}
                alt=""
                onDragStart={handleOnDragStart}
                className={styles.pichaz}
            />
            <div className={styles.chini} >
            <b className={styles.manenoz} >{c.maneno}</b>
            </div>
        </div>
    ))

    const responsive = {
        0 : {
            items : 3,
        },

        512 : {
            items: 3,
        },

        1024 : {
            items: 6,
        },
    }

  

  
  return (
    <AliceCarousel
                  autoPlay
                  responsive={responsive} 
                  mouseDragEnabled 
                  mouseTracking
                  infinite
                  disableDotsControls
                  disableButtonsControls
                  items={items} />
    
  )
}

export default AliceCaru 