import { Facebook, Instagram, LinkedIn, StayPrimaryLandscapeSharp, Twitter, YouTube } from '@material-ui/icons'
import React from 'react'
import styles from "../styles/footer.module.css"
import useStyles from "./icons"
import logo from "../images/MASTERMINDZ LOGZ.png"
import { Link } from 'react-router-dom'


function Footer() {
   const classes = useStyles() 

  return (
    <div className={styles.Footer} >
        <div className={styles.futi} >      
          <div className={styles.iconas} >
          <Link className={styles.linc} to="https://twitter.com/Zero_Dalali_Tz" target='blank' rel="noopener norefferer"  >
                                      <Twitter className={classes.icon4} />
          </Link>
            
            
            <Link to="https://www.instagram.com/zero_dalali/" target='blank' rel='noopener norefferer' className={styles.linc} >
             <Instagram className={classes.icon4} />
            </Link>
            <Link to="https://www.youtube.com/channel/UCkBbxTJMtcXpDCZpkkrEV2Q"  target="blank" rel='noopener noreferrer' className={styles.linc} >
             <YouTube className={classes.icon4} />
            </Link>
             <LinkedIn className={classes.icon4} />
            <Link to="https://www.facebook.com/profile.php?id=100094140002001" target='blank' rel='noopener noreferrer' className={styles.linc} >
             <Facebook className={classes.icon4} />
            </Link>
            
        </div>  

        <div className={styles.marks} >
        <Link to="/about"  className={styles.linc} > <h4 className={styles.mark} >Kuhusu sisi</h4> </Link>            <h4 className={styles.mark} >Careers</h4>
            
            <Link to="/adverts" className={styles.linc} ><h4 className={styles.mark} >Tangaza nasi</h4></Link>
            <Link to="https://mindz-582bb.web.app/" target='blank' rel='noopener norefferer' className={styles.linc} >
            <h4 className={styles.mark} >Great mindz Tz</h4>
            </Link>         
               <Link to="https://mindzwebsites.web.app/" target='blank' rel='noopener norefferer' className={styles.linc} >
            <h4 className={styles.mark} >Tengenezewa website</h4>
            </Link>
            <Link to="https://mindzmovies-79127.web.app/" target='blank' rel='noopener norefferer' className={styles.linc} >
            <h4 className={styles.mark} >Mindz Movies</h4>
            </Link>
            

        </div>
        </div>
      <div className={styles.tag}>
        <h3 className={styles.company}>Zero-Dalali</h3>
        <div className={styles.lagos} >
          <h4>Brought to you by the</h4>
          <img src={logo} alt='master' className={styles.master}/>          
        </div>
       <h4 className={styles.copy} >&#64;copyright 2023</h4>
      </div>
    </div>
  )
}

export default Footer