import React from 'react'
import Head from '../components/Head'
import House from '../components/House'
import Footer from '../components/Footer'
import { useParams } from 'react-router-dom';
import AliceCaru from '../components/AliceCaru';

function Single() {
  const {id} = useParams();
  console.log("we got the id here")

  return (
    <>
      
      
    <Head/>
    <House id={id} />
    <AliceCaru/>
    <Footer/>
    </>
  )
}

export default Single