// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { browserSessionPersistence, signInWithRedirect, inMemoryPersistence,GoogleAuthProvider,
   getAuth, onAuthStateChanged,
   setPersistence, browserLocalPersistence } from "firebase/auth";
import {initializeApp, getApp, getApps} from  "firebase/app";
import {getFirestore, persistentLocalCache} from "firebase/firestore"
import {getStorage} from "firebase/storage"
import { getMessaging, isSupported } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA9aBYeCY55P5R8e0eRDLB7bAHZfvSYKPM",
  authDomain: "zerodalali-21df3.firebaseapp.com",
  projectId: "zerodalali-21df3",
  storageBucket: "zerodalali-21df3.appspot.com",
  messagingSenderId: "188990049830",
  appId: "1:188990049830:web:e4973cd8b38403784360e7",
  measurementId: "G-Y1TJ1RCPPG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore();
const storage = getStorage();
const auth = getAuth();
const provider = new GoogleAuthProvider() 
export const messaging = async () => await isSupported() &&  getMessaging(app)


export { auth, provider, storage};
export default db;
