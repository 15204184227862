import React from 'react'
import styles from "../styles/duka.module.css"
import ParanomaO2 from './ParanomaO2'
import ParanomaO3 from './ParanomaO3'
import ParanomaO4 from './ParanomaO4'
import ParanomaO5 from './ParanomaO5'
import ParanomaO1 from './ParanomaO1'
import ParanomaOz2 from './ParanomaOz2'
import ParanomaOz3 from './ParanomaOz3'
import ParanomaOz4 from './ParanomaOz4'
import ParanomaOz1 from './ParanomaOz1'

function Ofisi2() {
  return (
    <div className={styles.duka} >
          <ParanomaOz1/>
          <ParanomaOz2/>
          <ParanomaOz3/>
          <ParanomaOz4/>
          
    </div>
  )
}

export default Ofisi2