import React from 'react'
import styles from "../styles/duka.module.css"
import ParanomaD1 from './ParanomaD1'
import ParanomaD2 from './ParanomaD2'
import ParanomaD3 from './ParanomaD3'
import ParanomaD4 from './ParanomaD4'
import ParanomaD5 from './ParanomaD5'
import ParanomaDz1 from './ParanomaDz1'
import ParanomaDz2 from './ParanomaDz2'
import ParanomaDz3 from './ParanomaDz3'
import ParanomaDz4 from './ParanomaDz4'
import ParanomaDz5 from './ParanomaDz5'

function Duka2() {
  return (
    <div className={styles.duka} >
        <ParanomaDz1/>
        <ParanomaDz2/>
        <ParanomaDz3/>
        <ParanomaDz4/>
        <ParanomaDz5/>
    </div>
  )
}

export default Duka2