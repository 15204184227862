import React, { useState} from 'react'
import styles from "../styles/head.module.css"
import { Avatar } from '@material-ui/core'
import Menu from "@material-ui/icons/MenuOutlined"
import { Class, Close } from '@material-ui/icons'
import Profile from './Profile'
import { Link } from 'react-router-dom'
import { Modal } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { browserLocalPersistence, setPersistence, signInWithPopup } from 'firebase/auth'
import { auth, provider } from '../firebase';
import { actionTypes } from '../reducer';
import { useStateValue } from '../StateProvider';
import { Home, BookOutlined, Mic, People, Web, Movie} from '@material-ui/icons'
import logz from "../images/logo complete.png"
import { addDoc, collection, getDoc, serverTimestamp } from 'firebase/firestore'
import db from  "../firebase"


function getModalStyle() {

 
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}% )`
  }
}


const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "black",
    position: 'absolute',
    top: "25vh",
    left: "15vw",
    width: "30%",
    height: "fit-content",
    border: '2px solid blue',
    [theme.breakpoints.down('sm')]:{
      width: "70%",
      left: "15%",
    },

    iconz: {
      color: 'red',
      marginTop: '2%',
      fontSize: '2vw',
      [theme.breakpoints.down('sm')]: { fontSize: "3.5vw"}
    },
    
  },
  

  
}))




function Head() {
  const [ modalStyle ] = useState(getModalStyle);
  const classes = useStyles();
  const [open2, setOpen2] = useState(false)
  const [open, setOpen] = useState(false)
  const [click, setClick] = useState(false)
  const [{user}, dispatch] = useStateValue()
  const [totals, setTotals] = useState(0)

  const clickz = () => {
    if(!click){
      setClick(true)
    }else{
      setClick(false)
    }
  }


 function SignIn () {
  setPersistence(auth, browserLocalPersistence)
  .then(() => {
    signInWithPopup(auth, provider).then(result => {
      const user = result.user
      const userDocRef = getDoc(db, "users", user?.uid)
      dispatch({
        type: actionTypes.SET_USER,
        user: user,
      })
     if(!userDocRef.exists()){
      const docRef =  addDoc(collection(db, 'users', user?.uid), {
        id: user?.uid,
        name: user?.displayName,
        Img: user?.photoURL,
        connections: false,
        lastonline: serverTimestamp(),
      });

     }
    
    }).catch((error) => {
        alert(error.message)
    })
  })
}



  const handleOpen = () => {
    if(user == null){
      setOpen2(true)
    }else{
      setOpen(!open)
    }
    
    
  }

   
  return (
    <div className={styles.heads} >

<Modal
  open={open2}
  onClose={(e) => {
    e.stopPropagation();
    setOpen2(false);
  }}
  >
  <div style={modalStyle} className={classes.paper} onClick={(e)=> {
    e.stopPropagation();
  }}>
     <h2 className={styles.karibu} >Karibu Zero Dalali</h2>
     <h4 className={styles.binya} >bonyeza Ingia ili uweze kuendelea mbele</h4>
      <button className={styles.ingia} onClick={(e)=> {
                                    e.stopPropagation();
                                    setOpen2(false);
                                    SignIn()
                                }} >Ingia</button>
  </div>
</Modal>


        <div className={styles.first} >
        <div className={styles.meinu} onClick={clickz} > {click ? <Close fontSize='inherit' /> : <Menu  fontSize='inherit' />} </div>
        <Link className={styles.linka} to="/" ><img src={logz} alt="log" className={styles.lebo} /></Link>
        
        
        <img src={user?.photoURL} alt={user ? user?.displayName : "Z"} className={styles.avatar} onClick={handleOpen} />
        {totals !== 0 && <span className={styles.namba} >{totals}</span>} 
        </div>
       {user && <Profile open={open} setOpen={setOpen} setTotals={setTotals} />}
        
       <div className={click ? styles.darkness : styles.darkness2} ></div>
        <div className={click ? styles.blocks : styles.blocks2}>
        <Link to="/" className={styles.lin} > <div className={styles.ico} ><Home fontSize='inherit' /></div> <h4 className={styles.optio}  >Nyumbani</h4></Link>
         <Link to="/about" className={styles.lin} ><div className={styles.ico} ><BookOutlined fontSize='inherit' /></div> <h4 className={styles.optio}  >Kuhusu sisi</h4></Link>
         <Link to="/adverts" className={styles.lin} ><div className={styles.ico} ><Mic fontSize='inherit' /></div> <h4 className={styles.optio}  >Tangaza nasi</h4></Link>
         <Link to="https://mindz-582bb.web.app/" target='blank' rel='noopener norefferer' className={styles.lin} >
         <div className={styles.ico} ><People fontSize='inherit' /></div><h4 className={styles.optio}  >Great mindz Tz</h4>
            </Link>
            <Link to="https://mindzwebsites.web.app/" target='blank' rel='noopener norefferer' className={styles.lin} >
            <div className={styles.ico} ><Web fontSize='inherit' /></div><h4 className={styles.optio}  >Tengenezewa website</h4>
            </Link>
            
            <Link to="https://mindzmovies-79127.web.app/" target='blank' rel='noopener norefferer' className={styles.lin} >
            <div className={styles.ico} ><Movie fontSize='inherit' /></div><h4 className={styles.optio}  >Mindz Movies</h4>
            </Link>
               
        </div>
    </div>
  )
}


export default Head