import React from 'react'
import styles from "../styles/duka.module.css"

import ParanomaK from './Paranomak'
import ParanomaK2 from './Paranomak2'
import ParanomaK3 from './Paranomak3'
import ParanomaK4 from './Paranomak4'

function Viwanja() {
  return (
    <div className={styles.duka} >
        <ParanomaK/>
        <ParanomaK2/>
        <ParanomaK3/>
        <ParanomaK4/>
    </div>
  )
}

export default Viwanja