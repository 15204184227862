import React from 'react'
import Carousel from 'react-material-ui-carousel'
import styles from "../styles/caru.module.css";
import { Paper, Button, StylesProvider } from '@material-ui/core';
import img1 from "../images/OIP (2).jpg"
import img2 from "../images/OIP (1).jpg";
import img3 from "../images/thumb-1920-330867.jpg";
import img4 from "../images/R44.jpg";



function Carusel(props)
{
    var items = [
        {
            name: "Pata Nyumba ya Ndoto Zako",
            description: "Zero dalali kabisa",
            sosi: img1,
        },
        {
            name: "Nyumba Bora Bila dalali",
            description: "Nyumba ya Kupanga yenye Nafasi kubwa",
            sosi: img2,
        },
        {
            name: "Tokomeza Dalali Sasa",
            description:"Pata Nyumba bila kutoa mwezi mmoja kwa dalali ",
            sosi: img3,
        },
        
        {
            name: "Unganishwa na Mwenye Nyumba Chap chap",
            description: "Wananchi wana haki kupata makazi bila shuruti",
            sosi: img4,
        }



    ]

    return (
        <Carousel>
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
     
            }
        </Carousel>

            
    )
}

function Item(props)
{
    return (
        <Paper className={styles.papers}>
               <div className={styles.ban}>
                <img  src={props.item.sosi} className={styles.imgz} />
                <div className={styles.imeji}>
                </div>
               <div className={styles.bans} >     
                <h1 className={styles.name}>{props.item.name}</h1>
                <p className={styles.maneno}>"{props.item.description}"</p>
                </div>

            </div>
     
        </Paper>
    )
}

export default Carusel