import React, { useState, useEffect} from 'react'
import styles from "../styles/body.module.css"
import { VpnKey, LocationOn, Hotel, Bathtub,LocalParking, EventSeat, LocalTaxi, Crop, AccessAlarm, CreditCard, Kitchen, Pool, Wifi, ArrowBack } from '@material-ui/icons'
import { Rating } from '@mui/material'
import Maps from './Maps'
import ParanomaT1 from './ParanomaT1'
import ParanomaT2 from './ParanomaT2'
import ParanomaT3 from './ParanomaT3'
import ParanomaT4 from './ParanomaT4'
import { useStateValue } from '../StateProvider'
import { onSnapshot,  query, orderBy, collection, where, getDocs, getCountFromServer } from 'firebase/firestore'
import db from '../firebase'
import ParanomaT5 from './ParanomaT5'
import { Navigate, useNavigate } from 'react-router-dom'
import Loader from '../loader/Loader'




function Tafuta({mkoa, wilaya, kata}) {
  const [select, setSelect] = useState(0)
  const [ open, setOpen] = useState(false)
  const [{catz}, dispatch] = useStateValue();
  const [catt, setCatt] = useState(catz)
  const [nyumba, setNyumba] = useState([]) 
  const [duka, setDuka] = useState([]) 
  const [ofisi, setOfisi] = useState([])
  const [go_down, setGo_down] = useState([]) 
  const [box, setBox] = useState("")
  const [kiwanja, setKiwanja] = useState([])
  const [idadi1, setIdadi1] = useState(0)
  const [idadi2, setIdadi2] = useState(0)
  const [idadi3, setIdadi3] = useState(0)
  const [idadi4, setIdadi4] = useState(0)
  const [idadi5, setIdadi5] = useState(0) 
  const [namba, setNamba] = useState()
  const navigate = useNavigate()


 
  const postref = collection(db, `${catt}`)
    const q1 = query(postref, where("property", "==", `Nyumba`), where("state", "==", `${mkoa}`), where("district", "==", `${wilaya}`), where("ward", "==", `${kata}`), orderBy("timestamp", "desc"))
    const q2 = query(postref, where("property", "==", `Duka`), where("state", "==", `${mkoa}`), where("district", "==", `${wilaya}`), where("ward", "==", `${kata}`), orderBy("timestamp", "desc"))
    const q3 = query(postref, where("property", "==", `Ofisi`), where("state", "==", `${mkoa}`), where("district", "==", `${wilaya}`), where("ward", "==", `${kata}`), orderBy("timestamp", "desc"))
    const q4 = query(postref, where("property", "==", `Go-down`), where("state", "==", `${mkoa}`), where("district", "==", `${wilaya}`), where("ward", "==", `${kata}`), orderBy("timestamp", "desc"))
    const q5 = query(postref, where("property", "==", `Kiwanja`), where("state", "==", `${mkoa}`), where("district", "==", `${wilaya}`), where("ward", "==", `${kata}`), orderBy("timestamp", "desc"))
    const [load , setLoad] = useState(true)
    const [load2 , setLoad2] = useState(false)
    const [load3 , setLoad3] = useState(false)
    const [load4 , setLoad4] = useState(false)
    const [load5 , setLoad5] = useState(false)



    useEffect(() => {
  
                const retrieve = async () => {
            try {
              const querySnap1 = await getDocs(q1)
              const counter = await getCountFromServer(q1)
              setIdadi1(counter.data().count)
              setNamba(counter.data().count)
              setNyumba(querySnap1.docs) 
            } catch (error) {
              console.error("Error fetching data", error);
            } finally {
              setLoad(false)
            }                    
        }
        retrieve()
         
     const retrieve1 = async () => {
         try {
           const querySnap2 = await getDocs(q2)
           const counter2 = await getCountFromServer(q2)
           setIdadi2(counter2.data().count)
           setDuka(querySnap2.docs) 
         } catch (error) {
           console.error("Error fetching data", error);
         } finally {
          console.log("")
         }                    
     }
     retrieve1()
   
   const retrieve2 = async () => {
       try {
        const querySnap3 = await getDocs(q3)
        const counter3 = await getCountFromServer(q3)
        setIdadi3(counter3.data().count)
         setOfisi(querySnap3.docs)
   
       } catch (error) {
         console.error("Error fetching data", error);
       } finally {
         setLoad3(false)
       }                    
   }
   retrieve2()

   const retrieve3 = async () => {
       try {
        const querySnap4 = await getDocs(q4)
        const counter4 = await getCountFromServer(q4)
        setIdadi4(counter4.data().count)
        setGo_down(querySnap4.docs)
       } catch (error) {
         console.error("Error fetching data", error);
       } finally {
         setLoad4(false)
       }                    
   }
   retrieve3()

   
   const retrieve4 = async () => {
       try {
        const querySnap5 = await getDocs(q5)
        const counter5 = await getCountFromServer(q5)
        setIdadi5(counter5.data().count)
         setKiwanja(querySnap5.docs)
          
   
       } catch (error) {
         console.error("Error fetching data", error);
       } finally {
         setLoad5(false)
       }                    
   }
   retrieve4()
     
  }, [db])

  
  


    
const [divs, setDivs] = useState([
  { id: 0, label: "NYUMBA", link: "/pag1"},
  { id: 1, label: "DUKA", link: "/pag2"},
  { id: 2, label: "OFISI", link: "/pag3"},
  { id: 3, label: "GO-DOWN", link: "/pag4"},      
])

   useEffect(() => {
    const buys = () =>{
      
      if(catz == "Kununua" && divs.length == 4){
          
          divs.push({id: 4, label: "KIWANJA", link: "/pag5"})
          
      }
     }
     buys()
   }, [])

   useEffect(() => {
     
    const changes = () => {
            if(select === 0){
        setBox("Nyumba zilizopo")
        setNamba(idadi1)
      }else if(select === 1){
        setBox("Maduka yaliyopo")
        setNamba(idadi2)
      }else if(select === 2){
        setBox("Ofisi zilizopo")
        setNamba(idadi3)
      }else if(select === 3){
        setBox("Go-down zilizopo")
        setNamba(idadi4)
      }else if(select === 4){
        setBox("Viwanja vilivyopo")
        setNamba(idadi5)
      }
    }
    
    changes()

   }, [select])

  const divClicked = (index) => {
        setSelect(index)
  }

  const HandleOpen = () => {
    setOpen(!open)
  }
    
 

  return (
    load ? <Loader/>   :
    <div className={styles.bodys} >
        <div className={styles.top}>
          
       <div className={styles.nyuma}  >
        
       </div>
       {catz === "Kupangisha" ? <h3 className={styles.labo} >Kupanga</h3> : <h3 className={styles.labo} >Kununua</h3>}
       </div>
          <h2 className={styles.wapi} >{box}  {mkoa},  {wilaya},  {kata} </h2>
             <h4 className={styles.namba}>{namba}</h4>
          <div className={styles.pango}>
            <div className={styles.jumla}>         
             {divs.map((div) => (
              <h2 className={styles.navy2} 
                  key={div.id}
                  onClick={() => divClicked(div.id)}
                  style={{
                    borderBottom: select === div.id ? "0.5vh solid red" : "none",
                    cursor: "pointer",
                 }} 
              >
                {div.label}
              </h2>
         ))}
       
         </div>
         <VpnKey className={styles.icona} onClick={HandleOpen} />
        <div className={open ?  styles.keys : styles.keys2} >
          
          <div className={open ?   styles.meaning : styles.meaningz} >
               <div className={styles.means} ><CreditCard className={styles.con} /><h4 className={styles.con2} >Kodi kwa mwezi</h4></div>
               <div className={styles.means} ><Hotel className={styles.con} /><h4 className={styles.con2} >vyumba vya kulala</h4></div>
               <div className={styles.means} ><Bathtub className={styles.con} /><h4 className={styles.con2} >Choo na bafu ndani ya nyumba</h4></div>
               <div className={styles.means} ><Crop className={styles.con} /> <h4 className={styles.con2} >Futi za mraba</h4> </div> 
               <div className={styles.means} ><LocalTaxi className={styles.con} /><h4 className={styles.con2} >Umbali kutoka lami</h4></div>
               <div className={styles.means} ><LocationOn className={styles.con} /><h4 className={styles.con2} >Mahali ilipo</h4></div>
               <div className={styles.means} ><EventSeat className={styles.con} /><h4 className={styles.con2} >Sebule</h4></div>
               <div className={styles.means} ><Kitchen className={styles.con} /><h4 className={styles.con2} >Jikoni</h4></div>
               <div className={styles.means} ><Pool className={styles.con} /><h4 className={styles.con2} >Bwawa la kuogelea</h4></div>
               <div className={styles.means} ><Wifi className={styles.con} /><h4 className={styles.con2} >Wifi au AC</h4></div>
               <div className={styles.means} ><AccessAlarm className={styles.con} /><h4 className={styles.con2} >Muda tokea ipostiwe</h4></div>
               <div className={styles.means} ><LocalParking className={styles.con} /><h4 className={styles.con2} >Parking ya gari</h4></div>
               
          </div>

          <div className={open ?  styles.meaning2 : styles.meaning2Z} >
              <div className={styles.rates} >
              <Rating
                  className={styles.ratz}
                  name="read-oly"
                  value={1}
                  readOnly
              />   <div className={styles.Rt} >
                <Hotel className={styles.con3} />
                <Bathtub className={styles.con3} /></div>
              </div>
              <div className={styles.rates} >
              <Rating
                  className={styles.ratz}
                  name="read-oly"
                  value={2}
                  readOnly
              />   <div className={styles.Rt} >
                <Hotel className={styles.con3} />
                <Bathtub className={styles.con3} />
                <Kitchen className={styles.con3} /></div>
              </div>
              <div className={styles.rates} >
              <Rating
                  className={styles.ratz}
                  name="read-oly"
                  value={3}
                  readOnly
              />   <div className={styles.Rt} >
                <Hotel className={styles.con3} />
                <Bathtub className={styles.con3} />
                <Kitchen className={styles.con3} />
                <EventSeat className={styles.con3} /></div>
              </div>
              <div className={styles.rates} >
              <Rating
                  className={styles.ratz}
                  name="read-oly"
                  value={4}
                  readOnly
              />   <div className={styles.Rt} >
                <Hotel className={styles.con3} />
                <Bathtub className={styles.con3} />
                <Kitchen className={styles.con3} />
                <EventSeat className={styles.con3} />
                <LocalParking className={styles.con3} /></div>
              </div>
              <div className={styles.rates} >
              <Rating
                  className={styles.ratz}
                  name="read-oly"
                  value={5}
                  readOnly
              />   <div className={styles.Rt} >
                <Hotel className={styles.con3} />
                <Bathtub className={styles.con3} />
                <Kitchen className={styles.con3} />
                <EventSeat className={styles.con3} />
                <LocalParking className={styles.con3} />
                <Wifi className={styles.con3} />
                <Pool className={styles.con3} /></div>
              </div>
          </div>
        </div>
          </div>

          {select === 0 &&     <ParanomaT1 nyumba={nyumba} kata={kata} />}

{select === 1 &&   <ParanomaT1 duka={duka} kata={kata} />}
  
  {select === 2 &&   <ParanomaT1 ofisi={ofisi} kata={kata} />}

{select === 3 &&  <ParanomaT1 go_down={go_down} kata={kata} />}


{select === 4 &&  <ParanomaT1 kiwanja={kiwanja} kata={kata} />}
        

           

          <div className={styles.neighbour} >
              <div className={styles.use} > 
                   <h3>Pata taarifa kuhusu mtaa unaotaka kuamia</h3>
                   <h5>Tumia ramani kuangalia vitu vya msingi kuhusu mtaa unaotaka kuhamia kama vile shule zilizopo karibu, vituo vya afya , barabara, hoteli n.k</h5>
              </div>
              <Maps/>
          </div>
    </div>
  )
}

export default Tafuta