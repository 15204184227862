import "../styles/App.css"
import React, { useState , useEffect} from 'react';
import Searchz from "@material-ui/icons/SearchOutlined"
import Down from "@material-ui/icons/ArrowDropDownOutlined"
import styles from "../styles/conco.module.css"




const CascadingOptions = ({trial, setSmkoa, setSwilaya, setSkata}) => {
  // Define the districts and wards
  const Tanzania = ["Arusha", "Dar_es_salaam", "Dodoma", "Geita", "Iringa", "Kagera", "Katavi", "Kigoma", "Kilimanjaro", "Lindi", "Manyara", "Mara", "Mbeya", "Morogoro", "Mtwara", "Mwanza", "Njombe", "Pwani", "Rukwa", "Ruvuma", "Shinyanga", "Simiyu", "Singida", "Songwe", "Tabora", "Tanga", "Pemba_kaskazini", "Unguja_kaskazini", "Pemba_kusini", "Unguja_kusini", "Unguja_magharibi", "Unguja_mashariki"];
  const districts = {
    Arusha: ["Arusha_Mjini","Monduli", "Arumeru_Mashariki", "Arumeru_Magharibi", "Ngorongoro", "Karatu", "Longido"],
    Dar_es_salaam: ["Ilala", "Segerea", "Ukonga", "Kigamboni", "Kawe", "Kinondoni", "Mbagala", "Temeke", "Kibamba", "Ubungo"],
    Dodoma:["Dodoma_mjini", "Kondoa_mjini", "Bahi", "Chamwino", "Mvumi", "Chemba", "Kondoa", "Kongwa", "Kibakwe", "Mpwapwa"],
    Geita:["Geita_mjini", "Nyanghwale", "Bukombe", "Chato", "Busanda", "Geita", "Mbogwe"],
    Iringa:["Iringa_mjini", "Mafinga_mjini", "Ismani", "Kalenga", "Kilolo", "Mufindi_kaskazini", "Mufindi_kusini"],
    Kagera:["Bukoba_mjini", "Biharamulo_magharibi", "Bukoba_vijijini", "Karagwe", "kyerwa", "Nkenge", "Muleba_kaskazini", "Muleba_kusini", "Ngara"],
    Katavi:["Mpanda_mjini", "Katavi", "Mpanda_vijijini", "Kavuu", "Nsimbo"], 
    Kigoma:["Kigoma_mjini", "Kasulu_mjini", "Buhigwe", "Buyungu", "Kasulu_vijijini", "Muhambwe", "Kigoma_kaskazini", "Kigoma_kusini"],
    Kilimanjaro:["Moshi_mjini", "Hai", "Moshi_vijijini", "Vunjo", "Mwanga", "Rombo", "Same_magharibi", "Same_mashariki", "Siha"],
    Lindi:["Lindi_mjini", "Mchinga", "Kilwa_kaskazini", "Kilwa_kusini", "Liwale", "Mtama", "Nachingwea", "Ruangwa"],
    Manyara:["Babati_mjini", "Mbulu_mjini", "Babati_vijijini", "Hanang", "Kiteto", "Mbulu_vijijini", "Simanjiro"],
    Mara:["Musoma_mjini", "Bunda_mjini", "tarime_mjini", "Bunda", "Mwibara", "Butiama", "Musoma_vijijini", "Rorya", "Serengeti", "Tarime_vijijini"],
    Mbeya:["Mbeya_mjini", "Busokelo", "Lupa", "Kyela", "Mbarali", "Mbeya_vijijini", "Rungwe"],
    Morogoro:["Morogoro_mjini", "Kilombero", "Gairo", "Kilosa", "Mikumi", "Malinyi", "Mlimba", "Morogoro_kusini", "Morogoro_kusini_mashariki", "Mvomero", "Ulanga"],
    Mtwara:["Mtwara_mjini", "Masasi_mjini", "Nanyamba", "Newala_mjini", "Lulindi", "Ndanda", "Mtwara_vijijini", "Nanyumbu", "Newala_vijijini", "Tandahimba" ],
    Mwanza:["Nyamagana", "Ilemela", "Buchosa", "Kwimba", "Sumve", "Magu", "Misungwi", "Sengerema", "Ukerewe"],
    Njombe:["Makambako", "Njombe_mjini", "Ludewa", "Makete", "Lupembe", "Wangingombe"],
    Pwani:["Kibaha_mjini", "Bagamoyo", "Chalinze", "Kibaha_vijijini", "Kibiti", "Kisarawe", "Mafia_", "Mkuranga", "Rufiji"],
    Rukwa:["Sumbawanga_mjini", "Kalambo", "Nkasi_kaskazini", "Nkasi_kusini", "Kwela"],
    Ruvuma:["Songea_mjini", "Mbinga_mjini", "Madaba", "Mbinga_vijijini", "Namtumbo", "Nyasa", "Peramiho", "Tunduru_kaskazini", "Tunduru_kusini"],
    Shinyanga:["Solwa","Shinyanga_mjini", "Kahama_mjini", "Kishapu", "Msalala", "Ushetu"],
    Simiyu:["Bariadi", "Busega", "Itilima", "Maswa_magharibi", "Maswa_mashariki", "kisesa", "Meatu"],
    Singida:["Singida_mjini", "Singida_magharibi", "Singida_mashariki", "Iramba_magharibi", "Manyoni_magharibi", "Manyoni_mashariki", "Iramba_mashariki", "Singida_kaskazini"],
    Songwe:["Tunduma", "ILeje", "Mbozi", "Vwawa", "Momba", "Songwe_"],
    Tabora:["Tabora_mjini", "Nzega_mjini", "Igunga", "Manonga", "Kaliua", "Ulyankulu", "Bukene", "Nzega_vijijini", "Sikonge", "Urambo", "Igalula", "Tabora_kaskazini"],
    Tanga:["Tanga_mjini", "Handeni_mjini", "Korogwe_mjini", "Bumbuli", "Handeni_vijijini", "Kilindi", "Korogwe_vijijini", "Lushoto", "Mlalo", "Mkinga", "Muheza", "Pangani"],
    Pemba_kaskazini:["Konde", "Micheweni", "Tumbe", "Wingwi", "Gando", "Kojani", "Mtambwe", "Pandani", "Wete"],
    Unguja_kaskazini:["Chaani", "Kijini", "Mkwajuni", "Nungwi", "Tumbatu", "Bumbwini", "Donge", "Mahonda"],
    Pemba_kusini:["Chakechake", "Chonga", "Ole", "Wawi", "Ziwani", "Chambani", "Kiwani", "Mkoani", "Mtambile"],
    Unguja_kusini:["Chwaka", "Tunguu", "Uzini", "Makunduchi", "Paje"],
    Unguja_magharibi:["Bububu", "Mfenesini", "Mtoni", "Mwera", "Welezo", "Dimani", "Fuoni", "Kiembesamaki", "Mwanakwerekwe", "Pangawe"],
    Unguja_mashariki:["Amani", "Chumbuni", "Jangombe", "Kikwajuni", "Kwahani", "Magomeni", "Malindi", "Mpendae", "Shaurimoyo"],
  };


  const wards = {
    ILeje: ["BUPIGU", "CHITETE", "IBABA", "IKINGA", "ISONGOLE", "ITALE", "ITUMBA", "KAFULE", "KALEMBO", "LUBANDA", "LUSWISI", "MALANGALI", "MBEBE", "MLALE", "NDOLA", "NGULILO", "NGULUGULU", "SANGE"],
    kisesa: ["ISENGWA", "ITINJE", "KISESA", "LINGEKA", "LUBIKA", "MBUGAYABANHYA", "MWABUMA", "MWABUSALI", "MWAKISANDU", "MWANDOYA", "MWASENGELA", "SAKASAKA", "TINDABULIGI"],
    Mafia_: ["BALENI", "JIBONDO", "KANGA", "KIEGEANI", "KILINDONI", "KIRONGWE", "MIBURANI", "NDAGONI"],
    Nyamagana:["KISHILI", "BUHONGWA", "BUTIMBA", "IGOGO", "IGOMA", "ISAMILO", "LUCHELELE", "LWANHIMA", "MABATINI", "MAHINA", "MBUGANI", "MHANDU", "MIRONGO", "MKOLANI", "MKUYUNI", "NYAMAGANA", "NYEGEZI", "PAMBA"],
    Busokelo:["ISANGE", "ITETE", "KABULA", "KAMBASEGELA", "KANDETE", "KISEGESE", "LUFILYO", "LUPATA", "LUTEBA", "LWANGWA", "MPATA", "MPOMBO", "MTABA"],
    tarime_mjini:["BOMANI", "KENAMANYORI", "KETARE", "MKENDE", "NYAMISANGURA", "NYANDOTO", "SABASABA", "TURWA"],
    Babati_vijijini:["ARRI", "AYALAGAYA", "BASHNET", "BOAY", "DABIL", "DAREDA", "DURU", "ENDAKISO", "GALLAPO", "GIDAS", "KIRU", "KISANGAJI", "MADUNGA", "MAGARA", "MAGUGU", "MAMIRE", "MWADA", "NAR", "NKAITI", "QAMEYU", "OASH", "RIRODA", "SECHEDA", "UFANA"],
    Mpanda_vijijini:["BULAMATA", "IKOLA", "ILANGU", "IPWAGA", "ISENGULE", "KABUNGU", "KAPALAMSENGA", "KAREMA", "KASEKESE", "KATUMA", "MISHANGO", "MNYAGALA", "MPANDANDOGO", "MWESE", "SIBWESA", "TONGWE"],
    kyerwa:["BUGARA", "BUGOMORA", "BUSINDE", "ISINGIRO", "ITEERA", "KAISHO", "KAKANIA", "KAMULI", "KIBARE", "KIBINGO", "KIKUKURU", "KIMULI", "KITWE", "KITWECHENKURA", "IKERWA", "MABIRA", "MURONGO", "NKWENDA", "NYAKATUNTU", "NYARUZUMBURA", "RUKURALIO", "RUTUNGURU", "RWABWERE", "SONGAMBELE"],
    Konde: ["KIFUNDI", "KIIPANGE", "KONDE", "MAKANGALE", "MSUKA MAGHARIBI", "MSUKA MASHARIKI", "TONDOONI"],
    Arusha_Mjini:["BARAA", "DARAJA III", "ELERAI", "ENGUTOTO", "KALOLENI", "KATI", "KIMANDOLU", "LEMARA", "LEVOLOSI", "MOIVARO", "MOSHONO", "MURIETI", "NGARENARO", "OLASITI", "OLMOTI", "OLOIRIENI", "OSUNYAI JR", "SAKINA", "SEKEI", "SINONI", "SOKONI I", "SOMBETINI", "TERRAT", "THEMI", "UNGALIMITED"],
    Shaurimoyo:["KWAMTIPURA", "MAPINDUZI", "MBORIBORINI", "MKELE", "SAATENI", "SHAURIMOYO"],
    Mpendae:["KILIMANI", "KWABITIHAMRANI", "MIGOMBANI", "MPENDAE"],
    Malindi:["GULIONI", "KIPONDA", "MAKADARA", "MALINDI", "MCHANGANI MJINI", "MITIULAYA", "MKUNAZINI", "MLANDEGE", "MWEMBETANGA", "SHANGANI", "VIKOKOTONI"],
    Magomeni:["KWAMTUMWAJENI", "MAGOMENI", "MEYA", "NYERERE", "SOGEA"],
    Kwahani:["KWAALAMSHA", "KWAHANI", "MIKUNGUNI", "MUUNGANO", "SEBLENI"],
    Kikwajuni:["KIKWAJUNI BONDENI", "KIKWAJUNI JUU", "KISIMA MAJONGOO", "KISIWANDUI", "MIEMBENI", "MNAZIMMOJA", "MUEMBELADU", "MUEMBEMADEMA", "MUEMBESHAURI", "RAHALEO"],
    Jangombe:["JANG'OMBE", "KIDONGOCHEKUNDU", "KWAALINATO", "MATARUMBETA", "URUSI"],
    Chumbuni:["BANKO", "KARAKANA", "CHUMBUNI", "MARUHUBI", "MASUMBANI", "MUEMBEMAKUMBI"],
    Amani:["AMANI", "KILIMAHEWA BONDENI", "KILIMAHEWA JUU", "KWAWAZEE"],
    Pangawe:["KIJITOUPELE", "KINUNI", "MNARANI", "MUEMBE MAJOGOO", "PANGAWE", "UZI"],
    Mwanakwerekwe:["JITIMAI", "MAGOGONI", "MELINNE", "MIKARAFUUNI", "MWANAKWEREKWE", "SOKONI", "TAVETA"],
    Kiembesamaki:["CHUKWANI", "KIEMBESAMAKI", "KWAMCHINA", "MBWENI", "MICHUNGWANI", "MOMBASA", "SHAKANI"],
    Fuoni:["CHUNGA", "FUONI KIPUNGANI", "FUONI MIGOMBANI", "KIBONDENI", "MAMBOSASA", "UWANDANI"],
    Dimani:["BWELEO", "DIMANI", "FUMBA", "KISAUNI", "KOMBENI", "MAUNGANI", "NYAMANZI", "TOMONDO"],
    Welezo:["HAWAII", "MICHIKICHINI", "MTOFAANI", "MTOPEPO", "MUNDULI", "UHOLANZI", "WELEZO"],
    Mwera:["KIANGA", "MASINGINI", "MTONI CHEMCHEM", "MTONI KIDATU", "MUEMBEMCHOMEKE", "MWERA"],
    Mtoni:["KIBWENI", "KWAGOA", "MTONI", "MWANYANYA", "SHARIFUMSA"],
    Mfenesini:["BUMBWISUDI", "CHUINI", "KAMA", "KIHINANI", "KIKAANGONI", "MFENESINI", "MWAKAJE"],
    Bububu:["BUBUBU", "CHEMCHEM", "DOLE", "KIJICHI", "KIZIMBANI", "MBUZINI"],
    Paje:["BWEJUU", "DINGWE", "JAMBIANI KIBIGUA", "JAMBIANI KIKADINI", "KITOGANI", "MICHAMVI", "MUUNGONI" ,"PAJE"],
    Makunduchi:["KAJENGWA", "KIBUTENI", "KIJINI", "KIONGOZI", "KIZIMKAZI DIMBANI", "KIZIMKAZI MKUNGUNI", "MTENDE", "MUYUNI A", "MUYUNI B", "MUYUNI C", "MZURI", "NGANANI", "TASANI"],
    Uzini:["BAMBI", "GHANA", "KIBOJE MKWAJUNI", "KIBOJE MWEMBESHAURI", "KIDIMNI", "KIJIBWENTU", "KOANI", "MACHUI", "MCHANGANI SHAMBA", "MGENI HAJI", "MITAKAWANI", "MIWANI", "MPAPA", "PAGALI", "TUNDUNI", "UMBUJI", "UZINI"],
    Tunguu:["BINGUNI", "BUNGI", "DUNGA BWENI", "DUNGA KIEMBENI", "JUMBI", "TINDINI", "KIKUNGWI", "TUNGUU", "UBAGO", "UNGUJA UKUU KAEBONA", "UNGUJA UKUU KAEPWANI", "UZI", "UZI NG'AMBWA"],
    Chwaka:["CHARAWE", "CHEJU", "CHWAKA", "JENDELE", "MARUMBI", "MSEWENI", "MUEMBEPUNDA", "PETE", "PONGWE", "UKONGORONI", "UROA", "ZUWIYANI"],
    Mtambile:["CHOLE", "KANGANI", "KENGEJA", "KISIWAPANZA", "KUUKUU", "MINAZINI", "MIZINGANI", "MJIMBINI", "MKUNGU", "MTAMBILE"],
    Mkoani:["CHANGAWENI", "CHOKOCHO", "MAKOMBENI", "MAKOONGWE", "MBUGUWANI", "MBUYUNI", "MICHENZANI", "MKANYAGENI", "NG'OMBENI", "SHIDI", "STAHABU", "UWELENI"],
    Kiwani:["JOMBWE", "KENDWA", "KIWANI", "MCHAKWE", "MTANGANI", "SHAMIANI"],
    Chambani:["CHAMBANI", "DODO", "MGAGADU", "NGWACHANI", "UKUTINI", "WAMBAA"],
    Ziwani:["KWALE", "MBUZINI", "MICHUNGWANI", "NDAGONI", "WESHA", "ZIWANI"],
    Wawi:["GOMBANI", "KIBOKONI", "MGOGONI", "MKOROSHONI", "MVUMONI", "WARA", "WAWI"],
    Ole:["MCHANGA MRIMA", "MJINI OLE", "NG'AMBWA", "OLE", "UWANDANI", "VITONGOJI"],
    Chonga:["CHONGA", "KILINDI", "MATALE", "MFIKIWA", "MGELEMA", "PUJINI"],
    Chakechake:["CHACHANI", "CHANJAANI", "KICHUNGWANI", "MADUNGU", "MSINGINI", "SHUNGI", "TIBIRIZI"],
    Mahonda:["KILOMBERO", "KIUNDUNI", "KISONGONI", "KITOPE", "KIWENGWA", "KWAGUBE", "MAHONDA", "MATETEMA", "MBALENI", "MGAMBO", "PANGENI", "UPENGENI", "UPENJA"],
    Donge:["DONGE KARANGE", "DONGE MBIJI", "DONGE MTAMBILE", "DONGE PWANI", "DONGE VIJIBWENI", "MAJENZI", "MKATALENI", "MNYIMBI", "NJIA YA MTONI"],
    Bumbwini:["FUJONI", "KIDANZINI", "KIOMBAMVUA", "KIONGWE KIDOGO", "MAFUFUNI", "MANGAPWANI", "MISUFINNI", "MKADINI", "ZINGWEZINGWE"],
    Tumbatu:["GOMANI", "JONGOWE", "KIPANGE", "MCHANGANI", "MKOKOTONI", "MTAKUJA", "MTO WA PWANI", "MUWANDA", "PALE", "UVIVINI"],
    Nungwi:["BANDAKUU", "BWEREU", "FUKUCHANI", "KIDOTI", "KIGUNDA", "KILIMANI TAZARI", "KILINDI", "KIUNGANI", "TAZARI"],
    Mkwajuni:["CHUTAMA", "GAMBA", "KIBENI", "KIDOMBO", "MATEMWE KASKAZINI", "MATEMWE KUSINI", "MKWAJUNI", "MOGA"],
    Kijini:["JIGA KUU", "KIGOMANI", "KIGONGONI", "KIJINI MATEMWE", "KIVUNGE", "MBUYU TENDE", "MUWANGE", "PITANAZAKO", "POTOA"],
    Chaani:["BANDAMAJI", "CHAANI KUBWA", "CHAANI MASINGINI", "KANDWI", "KIKOBWENI", "KINYASINI", "MCHEZA SHAURI", "PWANI MCHANGANI"],
    Wete:["BOPWE", "JADIDA", "KIPANGANI", "MTEMANI", "SELEM", "UTAANI"],
    Pandani:["KIUNGONI", "MAZIWANI", "MJANANZA", "MLINDO", "MZAMBARAO TAKAO", "PANDANI", "PEMBENI", "SHENGEJUU"],
    Mtambwe:["KISIWANI", "LIMBANI", "MTAMBWE KASKAZINI", "MTAMBWE KUSINI", "PIKI"],
    Kojani:["CHWALE", "KAMBINI", "KANGAGANI", "KINYIKANI", "KIUYU KIGONGONI", "KIUYU MINUNGWINI", "KOJANI", "MCHANGA MDOGO", "MPAMBANI"],
    Gando:["FINYA", "GANDO", "FUNDO", "JUNGUNI", "KINYASINI", "KIZIMBANI", "MGOGONI", "UKUNJWI"],
    Wingwi:["MJINI WINGWI", "MTEMANI", "SIZINI", "WINGWI MAPOFU", "WINGWI NJUGUNI"],
    Tumbe:["CHIMBA", "KINOWE", "MIHOGONI", "SHUMBA VIAMBONI", "TUMBE MAGHARIBI", "TUMBE MASHARIKI"],
    Micheweni:["CHAMBONI", "KIUYU MBUYUNI", "MAJENZI", "MAZIWA NG'OMBE", "MICHEWENI", "SHANAKE", "SHUMBA MJINI"],
    Pemba_kaskazini:["KIFUNDI", "KIPANGE", "KONDE", "MAKANGALE", "MSUKA MAGHARIBI", "MSUKA MASHARIKI", "TONDOONI"],
    Pangani:["BUSHIRI", "BWENI", "KIMANGA", "KIPUMBWI", "MADANGA", "MASAIKA", "MIKINGUNI", "MKWAJA", "MWERA", "PANGANI MAGHARIBI", "PANGANI MASHARIKI", "TUNGAMAA", "UBANGAA"],
    Muheza:["AMANI", "BWEMBWERA", "GENGE", "KICHEBA", "KIGOMBE", "KILULU", "KISIWANI", "KWABADA", "KWAFUNGO", "KWAKIFUA", 'KWEMINGOLI', "KWEMKABALA", "KWEZITU", "LUSANGA", 'MAGILA', "MAGOROTO", "MAJENGO", "MAKOLE", "MASUGURU", "MRARAMO", "MBOMOLE","MSAMBA", "MISALAI", "MISOZWE", "MKUZI", "MLINGANO", "MPAPAYU", "MTINDIRO", "MGOMENI", "NKUMBA", "PANDE DRAJANI", "POTWE", "SONGA", "TANGANYIKA", "TINGENI", "TONGWE", "ZIRAI"],
    Mkinga:["BOMA", "BOSHA", "BWITI", "DALUNI", "DODA", "GOMBERO", "KIGONGOI MAGHARIBI", "KIGONGOI MASHARIKI", "KWALE", "MANZA", "MAPATANO", "MARAMBA", "MAYOMBONI", "MHINDURO", "MKINGA", "MNYENZANI", "MOA", "MTIMBWANI", "MWAKIJEMBE", "PARUNGU KASERA", "SIGAYA"],
    Mlalo:["DULE M", "HEMTOYE", "KWEMSHASHA", "LUKOZI", "LUNGUZA", "MALINDI", "MANOLO", "MBARAMO", "MBARU", "MLALO", "MNAZI", "MNG'ARO", "MTAE", "MWANGOI", "RANGWI", "SHAGAYU", "SHUME", "SUNGA"],
    Lushoto:["GARE", "KILOLE", "KWAI", "KWEKANGA", "KWEMASHAI", "LUSHOTO", "MAGAMBA", "MAKANYA", "MALIBWI", "MBWEI", "MIGAMBO", "MLOLA", "NGULWI", "NGWELO", "UBIRI"],
    Korogwe_vijijini:["BUNGU", "CHEKELEI", "DINDIRA", "FOROFORO", "HALE", "KALALANI", "KERENGE", "KIZARA", "KWAGUNDA", "KWASHEMSHI", "LEWA", "LUTINDI", "MAGAMBA KWALUKONGE", "MAGILA GEREZA", "MAGOMA", "MAKUMBA", "MAKUYUNI", "MASHEWA", 'MAZINDE', "MGWASHI", "MKALAMO", "MKOMAZI", "MKUMBARA", "MLUNGUI", "MNYUZI", "MOMBO", "MPALE", "MSWAHA", "VUGIRI"],
    Kilindi:["BOKWA", "JAILA", "KIIRASHI", "KIKUNDE", "KILINDI", "KILWA", "KIMBE", "KISANGASA", "KWEDIBOMA", "KWEKIVU", "LWANDE", "MABALANGA", "MASAGALU", "MKINDI", "MSANJA", "MVUNGWE", "NEGERO", "PAGWI", "SAUNYI", "SONGE", "TUNGULI"],
    Handeni_vijijini:["KABUKU", "KABUKU NDANI", "KANG'ATA", "KITUMBI", "KIVA", "KOMKONGA", "KWACHAGA", "KWALUGURU", "KWAMATUKU", "KWAMGWE", "KWAMSISI", "KWANKONJE", "KWASUNGA", "KWEDIZINGA", "MAZINGARA", "MGAMBO", "MISIMA", "MKATA", "NDOLWA", "SEGERA", "SINDENI"],
    Bumbuli:["BAGA", "BUMBULI", "DULE B", "FUNTA", "KISIWANI", 'KWEMKOMOLE', "MAHEZANGULU", "MAMBA", "MAYO", "MBUZII", "MGWASHI", "MILINGANO", "MPONDE", "NKONGOI", "SONI", "TAMOTA", "USAMBARA", "VUGA"],
    Korogwe_mjini:["BAGAMOYO", "KILOLE", "KWAMSISI", "KWAMNDOLWA", "MAGUNGA", "MAJENGO", "MANUNDU", "MASUGURU", "MGOMBEZI", "MTONGA", "OLDA KOROGWE"],
    Handeni_mjini:["CHANIKA", "KIDELEKO", "KONTE", "MWAMAGOME", "KWEDIYAMBA", "KWENJUGO", "MWABANDA", "MALEZI", "MDOE", "MLIMANI", "MSASA", "VIBAONI"],
    Tanga_mjini:["CENTRAL", "CHONGOLEANI", "CHUMBAGENI", "DUGA", "KIOMONI", "KIRARE", "MABAWA", "MABOKWENI", "MAGAONI", "MAJENGO", "MAKORORA", "MARUNGU", "MASIWANI", "MAWENI", "MNYANJANI", "MSAMBWENI", "MWANZANGE", "MZINGANI", "MZIZIMA", "NGAMIANI KASKAZINI", "NGAMIANI KATI", "NGAMIANI KUSINI", "NGUVUMALLI", "PONGWE", "TANGASISI", "TONGONI", "USAGARA"],
    Tabora_kaskazini:["BUKUMBI", "IBELAMILUNDI", "IBIRI", "IGULUNGU", "IKONGOLO", "ILOLANGULU", "ISIKIZYA", "ISILA", "KALOLA", "MABAMA", "MAGIRI", "MAKAZI", "NDONO", "NSIMBO", "NZUBUKA", "SHITAGE", "UFULUMA", "UPUGE", "USAGARI"],
    Igalula:["GOWEKO", "IGALULA", "KIGWA", "KIZENGI", "LOYA", "LUTENDE", "MISWAKI", "MIYENZE", "MMALE", "NSOLOLO", "TURA"],
    Urambo:["IMALAMAKOYE", "ITUNDU", "KAPILULA", "KASISI", "KILOLENI", "KIYUNGI", "MCHIKICHINI", "MUUNGANO", "NSENDA", "SONGAMBELE", "UGALLA", "UKONDAMOYO", "URAMBO", "USISYA", "USSOKE", "UYOGO", "UYUMBU", "VUMILIA"],
    Sikonge:["CHABUTWA", "IGIGWA", "IPOLE", "KILOLELI", "KILOLI", "KILUMBI", "KIPANGA", "KIPILI", "KISANGA", "KITUNDA", "MISHENI", "MKOLYE", "MOLE", "MPOMBWE", "NGOYWA", "NYAHUA", "PANGALE", "SIKONGE", "TUTUO", "USUNGA"],
    Nzega_vijijini:["BUDUSHI", "ISANZU", "LUSU", "MAGENGATI", "MBAGWA", "MILAMBO ITOBO", "MIZIBAZIBA", "MUHUGI", "MWAKASHANHALA", "MWANTUNDU", "MWASALA", "NATA", "NDALA", "NKINIZIWA", "PUGE", "TONGI", "UGEMBE", "UTWIGU", "WELA"],
    Bukene:["BUKENE", "IGUSULE", "IKINDWA", "ISAGENHE", "ITOBO", "KAHAMANHALANGA", "KARITU", "KASELA", 'MAMBALI', "MBUTU", "MOGWA", "MWAMALA", "MWANGOYE", "SEMEMBELA", "SHIGAMBA", "SIGILI", "EDUKA"],
    Ulyankulu:["ICHEMBA", "IGOMBEMKULU", "ILEGE", "KANINDO", "KANOGE", "KASHISHI", "KONANNE", "MAKINGI", "MILAMBO", "MWONGOZO", "NHWANDE", "SASU", "SELELI", "SILAMBO", "UYOWA"],
    Kaliua:["IGAGALA", "IGWISI", "KALIUA", "KAMSEKWA", "KAZAROHO", "UFUKUTWA", "UGUNGA", "UKUMBI SIGANGA", "USENYE", "USHOKOLA", "USIMBA", "ZUGIMLOLE"],
    Manonga:["CHABUTWA", "CHOMA CHA NKOLA", "IBOROGELO", "IGOWEKO", "KITANGILI", "MWAMALA", "MWASHIKIMBILI", "MWISI", "NDEMBEZI", "NGULU", "NKINGA", "NTOBO", "NYANDEKWA", "SIMBO", "SUNGWIZI", "TAMBALALE", "UGAKA", "USWAYA", "ZIBA"],
    Igunga:["BUKOKO", "IGUNGA", "IGURUBI", "ISAKAMALIWA", "ITUMBA", "ITUNDURU", "KINING'INILA", "KINUNGU", "LUGUBU", "MBUTU", "MTUNGURU", "MWAMAKONA", "MWAMASHIMBA", "NANGA", "NGUVUMOJA"],
    Nzega_mjini:["IJANIJA", "ITILO", "KITANGILI", "MBOGWE", "MIGUWA", "MWANZOLI", "NZEGA MJINI MAGHARIBI", "NZEGA MJINI MASHARIKI", "NZEGA NDOGO", "UCHAMA"],
    Tabora_mjini:["CHEMCHEM", "CHEYO", "GONGONI", "IFUCHA", "IKOMWA", "IPULI", "ISEVYA", "ITETEMIA", "ITONJANDA", "KAVILA", "KAKOLA", "KALUNDE", "KANYENYE", "KIDONGOCHEKUNDU", "KILOLENI", "KITETE", "MALOLO", "MAPAMBANO", "MBUGANI", "MISHA", "MPELA", "MTENDENI", "MWINYI", "NDEVELWA", "NG'AMBO", "NTALIKWA", "TAMBUKA-RELI", "TUMBI", "UYUI"],
    Songwe_:["CHANG'OMBE", "GALULA", "GUA", "IFWENKENYA", "KANGA", "KAPALALA", "MAGAMBA", "MANDA", "MBANGALA", "MBUYUNI", "MKWAJUNI", "MPONA", "MWAMBANI", "NAMKUKWE", "NGWALA", "SAZA", "TOTOWE", "UDINDE"],
    Momba:["CHILULUMO", "CHITETE", "IKANA", "IVUNA", "KAMSAMBA", "KAPELE", "MKOMBA", "MKULWE", "MPAPA", "MSANGANO", "MYUNGA", "NDALAMBO", "NKANGAMO", "NZOKA"],
    Vwawa:["HASAMBA", "HASANGA", "HEZYA", "ICHENJEZYA", "IDIWILI", "IHANDA", "ILOLO", "IPUNGA", "ISALALO", "IYULA", "KILIMAMPIMBI", "MLANGALI", "MSIA", "NANYALA", "NYIMBILI", "RUANDA", "UKWILE", "VWAWA"],
    Mbozi:["BARA", "HALUNGU", "IGAMBA", "ISANSA", "ITAKA", "ITUMPI", "MAGAMBA", "MAHENJE", "MLOWO", "NAMBINZO", "SHIWINGA"],
    Ileje:["BUPIGU", "CHITETE", "IBABA", "IKINGA", "ISONGOLE", "ITALE", "ITUMBA", "KAFULE", "KALEMBO", "LUBANDA", "LUSWISI", "MALANGALI", "MBEBE", "MLALE", "NDOLA", "NGULILO", "NGULUGULU", "SANGE"],
     Tunduma:["CHAPWA", "CHIPAKA", "CHIWEZI", "KALOLENI", "KATETE", "MAJENGO", "MAKAMBINI", "MAPOROMOKO", "MPANDE", "MPEMBA", "MUUNGANO", "MWAKAKATI", "SOGEA", "TUNDUMA", "UWANJANI"],
    Singida_kaskazini:["IKHANODA", "ILONGERO", "ITAJA", "KIJOTA", "KINYAGIGI", "KINYETO", "MAGHOJOA", "MAKURO", "MERYA", "MGORI", "MRAMA", "MSANGE", "MSISI", "MTINKO", "MUDIDA", "MUGHAMO", "MUGHUNGA", "MWASAUYA", "NGIMU", "NTONGE", "UGHANDI"],
    Iramba_mashariki:["GUMANGA", "IBAGA", "IGUGUNO", "ILUNDA", "KIKHONDA", "KINAMPUNDU", "KINYANGIRI", "MATONGO", "MIGANGA", "MPAMBALA", "MSINGI", "MWANGA", "MWANGEZA", "NDUGUTI", "NKALAKALA", "NKINTO", "TUMULI"],
    Manyoni_mashariki:["CHIKOLA", "CHIKUYU", "HEKA", "ISSEKE", "KINTINKU", "MAJIRI", "MAKANDA", "MAKURU", "MAKUTUPORA", "MANYONI", "MAWENI", "MKWESE", "MUHALALA", "NKONKO", "SANZA", "SARANDA", "SASAJINA", "SOLYA"],
    Manyoni_magharibi:["AGHONDI", "IDODYANDOLE", "IPANDE", "ITIGI MJINI", "ITIGI MAJENGO", "KALANGALI", "KITARAKA", "MGANDU", "MITUNDU", "MWAMAGEMBE", "RUNGWA", "SANJARANDA", "TAMBUKARELI"],
    Iramba_magharibi:["KASELYA", "KIDARU", "KINAMPANDA", "KIOMBOI", "KISIRIRI", "KYENGEGE", "MALUGA", "MBELEKESE", "MGONGO", "MTEKENTE", "MTOA", "MUKULU", "NDAGO", "NDULUNGU", "NTWIKE", "OLD-KIOMBOI", "SHELUI", 'TULYA', "ULEMO", "URUGHU"],
    Singida_mashariki:["DUNG'UNYI", "IKUNGI", "ISSUNA", "KIKIO", "LIGHWA", "MAKIUNGU", "MANG'ONYI", "MISUGHAA", "MKIWA", "MUNGAA", "NTUNTU", "SIUYU", "UNYAHATI"],
    Singida_magharibi:["IGHOMBWE", "IGLANSONI", "IHANJA", "IRISYA", "ISSEKE", "IYUMBU", "KITUNTU", "MAKILAWA", "MGUNGIRA", "MINYUGHE", "MTUNDURU", "MUHINTIRI", "MWARU", "PUMA", "SEPUKA"],
    Singida_mjini:["IPEMBE", "KINDAI", "KISAKI", "MAJENGO", "MANDEWA", "MINGA", "MISUNA", "MITUNDURUNI", "MTAMAA", "MTIPA", "MUGHANGA", "MUNGUMAJI", "MWANKOKO", "UHAMAKA", "UNYAMBWA", "UNYAMIKUMBI", "UNYIANGA", "UTEMINI"],
    Meatu:["BUKUNDI", "IMALASEKO", "KABONDO", "KIMALI", "MBUSHI", "MWABUZO", "MWAMALOLE", "MWAMANIMBA", "MWAMANONGU", "MWAMISHALI", "MWANGUDO", "MWANHUZI", "MWANJOLO", "MWANYAHINA", "NG'HOBOKO", "NKOMA"],
    Kisesa:["ISENGWA", "ITINJE", "KISESA", "LINGEKA", "LUBIGA", "MBUGAYABANHYA", "MWABUMA", "MWABUSALU", "MWAKISANDU", "MWANDOYA", "MWASENGELA", "SAKASAKA", "TINDABULIGI"],    
    Maswa_mashariki:["BINZA", "BUDEKWA", "BUGARAMA", "BUSILILI", "DAKAMA", "IPILILO", "LALAGO", "MBARAGANE", "MPINDO", "MWABARATURU", "MWAMANENGE", "NG'WIGWA", "NGULIGULI", "NYALIKUNGU", "SANGAMWALUGESHA", "SENANI", "SHANWA", "SOLA", "SUKUMA"],
    Maswa_magharibi:["BADI", "BUCHAMBI", "BUSANGI", "ISANGA", "JIJA", "KADOTO", "KULIMI", "MALAMPAKA", "MASELA", "MATABA", "MWABAYANDA", "MWAMASHIMBA", "MWANG'HONOLI", "NYABUBINZA", "SENG'WA", "SHISHIYU", "ZANZUI"],
    Itilima:["BUDALABUJIGA", "BUMERA", "CHINAMILI", "IKINDILO", "KINANGABILILI", "LUGURU", "MBITA", "MHUNZE", "MIGATO", "MWALUSHU", "MWAMAPALALA", "MWAMTANI", "MWASWALE", "NDOLELEZI", "NHOBORA", "NKOMA", "NKUYU", "NYAMALAPA", "SAGATA", "ZAWIDA", "ZAGAYU"],
    Busega:["BADUGU", "IGALUKILO", "IMALAMATE", "KABITA", "KALEMELA", "KILOLELI", "LAMADI", "LUTUBIGA", "MALILI", "MKULA", "MWAMANYILI", "NGASAMO", "NYALUHANDE", "MYASHIMO", "SHIGALA"],
    Bariadi:["BARIADI", "BUNAMHALA", "GUDUWI", "ISANGA", "MALAMBO", "NYAKABINDI", "NYANGOKOLWA", "SIMA", "SOMANDA", "BENEMHI", "DUTWA", "GAMBOSI", "GIBISHI", "GILYA", "IHUSI", "IKUNGULYABASHASHI", "ITUBUKILO", "KASOLI", "KILALO", "MASEWA", "MATONGO", "MWADOBANA", "MWASUBUYA", "MWAUBINGI", "MWAUMATONDO", "NGULYATI", "NKINDWABIYE", "NKOLOLO", "SAKWE", "SAPIWI"],
    Ushetu:["BUKOMELA", "BULUNGWA", "CHAMBO", "CHONA", "IDAHINA", "IGUNDA", "IGWAMANONI", "KINAMAPULA", "KISUKE", "MAPAMBA", "MPUNZE", "NYAMILANGANO", "NYANKENDE", "SABASABINI", "UBAGWE", "UKUNE", "ULEWE", "ULOWA", "USHETU", "UYOGO"],
    Solwa:["BUKENE", "DIDIA", "ILOLA", "IMESELA", "ISELAMAGAZI", "ITWANGI", "LYABUSALU", "LYAMIDATI", "MASENGWA", "MWAKITOLYO", "MWALUKWA", "MWAMALA", "MWANTINI", "MWENGE", "NSALALA", "NYAMALOGO", "NYIDA", "PANDAGICHIZA", "PUNI", "SALAWE", "SAMUYE", "SOLWA", "TINDE", "USANDA"],
    Msalala:["BUGARAMA", "BULIGE", "BULYAN'HULU", "BUSANGI", "CHELLA", "IKINDA", "ISAKA", "JANA", "KASHISHI", "LUNGUYA", "MEGA", "MWAKATA", "MWALUGUGLU", "MWANASE", "NGAYA", "NTOBO", "SEGESE", "SHILELA"],
    Kishapu:["BUBIKI", "BUNAMBIYU", "BUPIGI", "BUSANGWA", "IDUKILO", "IGAGA", "ITILIMA", "KILOLENI", "KISHAPU", "LAGANA", "MAGANZO", "MASANGA", "MONDO", "MWADUI LOHUMBO", "MWAKIPOYA", "MWAMALASA", "MWAMASHELE", "MWASUBI", "MWATAGA", "MWAWEJA", "NDOLELEJI", "NGOFILA", "SEKE-BUGORO", "SHAGIHILU", "SOMAGEDI", "SONGWA", "TALAGA", "UCHUNGA", "UKENYENGE"],
    Kahama_mjini:["BUSOKA", "ISAGEHE", "IYENZE", "KAGONGWA", "KAHAMA MJINI", "KILAGO", "KINAGA", "MAJENGO", "MALUNGA", "MHONGOLO", "MHUNGULA", "MONDO", "MWENDAKULIMA", "NGONGWA", "NYAHANGA", "NYANDEKWA", "NYASUBI", "NYIHOGO", "WENDELE", "ZONGOMERA" ],
    Shinyanga_mjini:["CHAMAGUHA", "CHIBE", "IBADAKUU", "IBINZAMATA", "KAMBARAGE", "KITANGILI", "KIZUMBI", "KOLANDOTO", "LUBAGA", "MASEKELO", "MJINI", "MWAMALILI", "MWAWAZA", "NDALA", "NDEMBEZI", "NGOKOLO", "OLD SHINYANGA"],
    Tunduru_kusini:["CHIWANA", "LIGOMA", "LUKUMBULE", "MARUMBA", "MBATI", "MBESA", "MCHESI", "MCHOTEKA", "MCHULUKA", "MISECHELA", "MTINA", "NALASI MAGHARIBI", "NALASI MASHARIKI", "NAMASAKATA", 'TUMEWACHO'],
    Tunduru_kaskazini:["JAKIKA", "KALULU", "KIDODOMA", "LIGUNGA", 'MAJENGO', "MAJIMAJI", "MASONYA", "MATEMANGA", "MCHANGANI", "MINDU", "MLINGOTI MAGHARIBI", "MLINGOTI MASHARIKI", "MUHUWESI" ,"NAKAPANYA", "NAKAYAYA", "NAMAKAMBALE", "NAMIUNGO", "NAMPUNGU", "NAMWINYU", "NANDEMBO", "NANJOKA", "NGAPA", "SISI KWA SISI", "TINGINYA"],
    Peramiho:["KILAGANO", "KIZUKA", "LIGANGA", 'LILAHI', "LITAPWASI", "LITISHA", "MAGAGURA", "MAPOSEHI", "MATIMIRA", "MBINGA MHARULE", "MPANDANGINDO", "MPITIMBI", "MUHUKURU", "NDONGOSI", "PARANGU", "PERAMIHO"],
    Nyasa:["CHIWANDA", "KIHAGARA", "KILOSA", 'KINGERIKITI', "LINGA", "LIPARAMBA", "LIPINGO", "LITUHI", "LIULI", "LIWUNDI", "LUHANGARASI", "LUMEME", "MBAHA", "MBAMBA BAY", "MIPOTOPOTO", "MPEPO", "MTIPWILI", "NGUMBO", "TINGI", "UPOLO"],
    Namtumbo:["HANGA", "KITANDA", "LIGERA", "LIKUYUSEKA", "LIMAMU", "LISIMONJI", "LITOLA", "LUCHILI", "LUEGU", "LUSEWA", "MAGAZINI", "MCHOMORO", "MGOMBASI", "MKONGO", "MKONGO GULIONI", "MPUTA", "MSINDO", "NAMABENGO", "NAMTUMBO", "RWINGA"],
    Mbinga_vijijini:["AMANI MAKOLO", 'KAMBARAGE', "KIGONSELA", "KIHANGI MAHUKA", "KIPAPA", "KIPOLOPOLO", "KITUMBALOMO", "KITURA", "LANGIRO", "LINDA", "LITEMBO", "LITUMBANDYOSI", "LUKARASI", "MAGUU", "MAPERA", "MATIRI", "MBUJI", "MHONGOZI", "MIKALANGA", "MKAKO", "MKUMBI", "MPAPA", "MUUNGANO", 'NAMSWEA', 'NGIMA', "MYONI", "RUANDA", "UKATA", "WUKIRO"],
    Madaba:["GUMBIRO", "LITUTA", "MAHANJE", "MATETEREKA", "MATUMBI", "MKONGOTEMA", "MTYANGIMBOLE", "WINO"],
    Mbinga_mjini:["BETHREHEMU", "KAGUGU", "KIHUNGU", "KIKOLO", "KILIMANI", "KITANDA", "LUHUWIKO", "LUSONGA", "LUWAITA", "MASUMUNI", "MATARAWE", "MATEKA", "MBAMBI", "MBANGAMAO", "MBINGA MJINI", "MBINGA MJINI B", "MPEPAI", "MYANGAYANGA", "UTIRI"],
    Songea_mjini:["BOMBAMBILI", "LILAMBO", "LIZABONI", "MAJENGO", "MATARAWE", "MATEKA", "MATOGORO", "MFARANYAKI", "MISUFINI", "MJIMWEMA", "MJINI", "MLETELE", "MSAMALA", "MSHANGANO", "MWENGEMSHINDO", "NDILIMALITEMBO", "RUHUWIKO", "RUVUMA", "SEEDFARM", "SUBIRA", "TANGA"],
    Kwela:["IKOZI", "ILEMBA", "KAENGESA", "KALAMBANZITE", "KALUMBALEZA", "KANDA", "KAOZE", "KAPENTA", "KASANZAMA", "KILANGAWANA", "KIPETA", "LAELA", "LUSAKA", "LYANGALILE", "MFINGA", "MIANGALILE", "MILEPA", "MNOKOLA", "MPUI", "MPWAPWA", "MSANDAMUUNGANO", "MTOWISA", "MUZE", "MWADUI", "NANKANGA", "SANDULULA", "ZIMBA"],
    Nkasi_kusini:["CHALA", "KALA", "KATE", "KIPANDE", "KIZUMBI", "MYULA", "NINDE", "NKANDASI", "NTUCHI", "SINTALI", "WAMPEMBE"],
    Nkasi_kaskazini:["ISALE", "ISUNTA", "ITETE", "KABWE", "KIPILI", "KIPUNDU", "KIRANDO", "KORONGWE", "MAJENGO", "MASHETE", "MKINGA", "MKWAMBA", "MTENGA", "NAMANYERE", "NKOMOLO", "NTATUMBILA", "PARAMAWE"],
    Kalambo:["KANYEZI", "KASANGA", "KATAZI", "KATETE", "KILESHA", "KISUMBA", "LEGEZA MWENDO", "LYOWA", "MAMBWEKENYA", "MAMBWE NKOSWE", "MATAI", "MBULUMA", "MKALI", "MKOWE", "MNAMBA", "POMBWE", "MSANZI", "MWAZYE", "MWIMBI", "SAMAZI", "SOPA", "SUNDU", "ULUMI"],
    Sumbawanga_mjini:["CHANJI", "IZIA", "KASENSE", "KATANDALA", "KIZWITE", "LWICHE", "MAFULALA", "MAJENGO", "MALANGALI", "MATANGA", "MAZWI", "MILANZI", "MOLLO", "MOMOKA", "MSUA", "NTENDO", "PITO", "SENGA", "SUMBAWANGA"],
    Rufiji:["CHEMCHEM", "CHUMBI", "IKWIRIRI", "KIPUGIRA", "MBWARA", "MGOMBA", "MKONGO", "MOHORO", "MWASENI", "NGARAMBE", "NGORONGORO", "UMWE", "UTETE"],
    Mkuranga:["BETA", "BUPU", "DONDO", "KIMANZICHANA", "KIPARAN'ANDA",  "KISEGESE", "KISIJU", "KITOMONDO", "LUKANGA", "MAGAWA", "MBEZI", "MIPEKO", "MKAMBA", "MKURANGA", "MSONGA", "MWANDEGE", "MWARUSEMBE", "NJIANNE", "NYAMATO", "PANZUO", "SHUNGUVWENI", "TAMBANI", "TENGELEA", "VIANZI", "VIKINDU"],
    Mafia:["BALENI", 'JIBONDO', "KANGA", "KIEGEANI", "KILINDONI", "KIRONGWE", "MIBURANI", "NDAGONI"],
    Kisarawe:["BOGA", "CHOLE", "KAZIMZUMBWI", "KIBUTA", "KILUVYA", "KISARAWE", "KURUI", "MAFIZI", "MANEROMANGO", "MARUI", "MARUMBO", "MASAKI", "MSANGA","MSIMBU", "MZENGA", "VIHINGO", "VIKUMBURU"],
    Kibiti:["BUNGU", "DIMANI", "KIBITI", "KIONGORONI", "MAHENGE", 'MAPARONI', "MBUCHI", "MCHUKWI", "MJAWA", "MLANZI", "MSALA", "MTAWANYA", "MTUNDA", "MWAMBAO", "RUARUKE", "SALALE"],
    Kibaha_vijijini:["BOKOMNEMELA", 'DUTUMI', "GWATA", "JANGA", "KIKONGO", 'KLANGALANGA', "KWALA", "MAGINDU", "MLANDIZI", "MTAMBANI", "MTONGANI", "RUVU", "SOGA"],
    Chalinze:["BWILINGU", "KIBINDU", "KIMANGE", "KIWANGWA", "LUGOBA", "MANDERA", "MBWEWE", "MIONO", "MKANGE", "MSOGA", "PERA", 'TELAWANDA', "UBENAZOMOZI", "VIGWAZA"],
    Bagamoyo:["DUNDA", "FUKAYOSI", "KEREGE",  "KIROMO", "KISUTU", "MAGOMEN", "MAKURUNGE", "MAPINGA", "NIANJEMA", "YOMBO", "ZINGA"],
    Kibaha_mjini:["KIBAHA", "KONGOWE", "MAILIMOJA", "MBWAWA", "MISUGUSUGU", "MKUZA", "MSANGANI", "PANGANI", "PICHA YA NDEGE", "SOFU", "TANGINI", "TUMBI", "VISIGA", "VIZIWA ZIWA"],
    Wangingombe:["IGIMA", "IGOSI", "IGWACHANYA", "ILEMBULA", "IMALINYI", "ITULAHUMBA", "KIDUGALA", "KIJOMBE", "KIPENGELE", "LUDUGA", "MAKOGA", "MALANGALI", "MDANDU", "SAJA", "UDONJA", "UHAMBULE", "UHENGA", "ULEMBWE", "USUKA", "WANGAMA", "WANGING'OMBE"],
    Lupembe:["IDAMBA", "IGONGOLO", "IKONDO", "IKUNA", "KICHIWA", "KIDEGEMBYE", "LUPEMBE", "MATEMBWE", "MFRIGA", "MTWANGO", "NINGA", "UKALAWA"],
    Makete:["BULONGWA", "IKUWO", "INIHO", "IPELELE", "ISAPULANO", "ITUNDU", "IWAWA", "KIGALA", "KIGULU", "KINYIKA", "KIPAGALO", "KITULO", "LUPALILO", "LUPILA", "LUWUMBU", "MANG'OTO", "MATAMBA", "MBALATSE", "MFUMBI", "MLONDWE", "TANDALA", "UKWAMA"],
    Ludewa:["IBUMI", "IWELA", "KILONDO", "LIFUMA", "LUANA", "LUBONDE", "LUDENDE", "LUDEWA", "LUGARAWA", "LUILO", "LUMBILA", "LUPANGA", "LUPINGU", "MADILU", "MADOPE", "MAKONDE", "MANDA", "MASASI", "MAVANGA", "MAWENGI", "MILO", "MKONGOBAKI", "MLANGALI", "MUNDINDI", "NKOMANG'OMBE", "RUHUHU"],
    Njombe_mjini:["IHANGA", "IWUNGILO", "KIFANYA", "LUGENGE", "LUPONDE", "MAKOWO", "MATOLA", "MJIMWEMA", "NJOMBE MJINI", "RAMADHANI", "UTALINGORO", "UWEMBA", "YAKOBI"],
    Makambako:["KITANDILILO", "KITISI", "KIVAVI", "LYAMKENA", "MAGUVANI", "MAHONGOLE", "MAJENGO", "MAKAMBAKO", "MJIMWEMA", "MLOWA", "MWEMBETOGWA", "UTENGULE"],
    Ukerewe:["BUKANDA", "BUKIKO", "BUKINDO", "BUKONGO", "BUKUNGU", "BWIRO", "BWISYA", "IGALLA", "ILANGALA", "IRUGWA", "KAGERA", "KAGUNGULI", "KAKEREGE", "KAKUKURU", "MUKITUNTU", "MURITI", "MURUTUNGURU", "NAKATUNGURU", "NAMAGONDO", "NAMILEMBE", "NANSIO", "NDURUMA", "NGOMA", "NKILIZYA", "NYAMANGA"],
    Sengerema:["BITOTO", "BUSISI", "BUYAGU", "BUZILASOGA", "CHIFUNFU", "IBISABAGENI", "IBONDO", "IGALULA", "IGULUMUKI", "KAGUNGA", "KAHUMULO", "KASENYI", "KASUNGAMILE", "KATUNGURU", "KISHINDA", "MISSION", "MWABALUHI", "NGOMA", "NYAMATONGO", "NYAMAZUGO", "NYAMIZEZE", "NYAMPANDE", "NYAMPULUKANO", "NYATUKARA", "SIMA", "TABARUKA"],
    Misungwi:["UKIRIGURU", "BUHINGO", "BUHUNDA", "BULEMEJI", "FELLA", "GULUMUNGU", "IDETEMYA", "IGOKELO", "ILUJAMATE", "ISENENGEJA", "KANYELELE", "KASOLOLO", "KIJIMA", "KOROMIJE", "LUBILI", "MABUKI", "MAMAYE", "MBARIKA", "MISASI", "MISUNGWI", "MONDO", "MWANIKO", "NHUNDULU", "SHILALO", "SUMBUGU", "USAGARA"],
    Magu:["BUHUMBI", "BUJASHI", "BUJORA", "BUKANDWE", "CHABULA", "ISANDULA", "ITUMBILI", "JINJIMILI", "KABILA", "KAHANGARA", "KANDAWE", "KISESA", "KITONGO SIMA", "KONGOLO", "LUBUGU", "LUTALE", "MAGU MJINI", "MWAMABANZA", "NG'AHAYA", "NKUNGULU", "NYAGUSE", "NYIGOGO", "SHISHANI", "SUKUMA"],
    Sumve:["BUGANDO", "BUNGULWA", "ISENI", "LYOMA", "MALIGISU", "MALYA", "MANTARE", "MWABOMBA", "MWAGI", "MWANDU", "NGULLA", "NKALALO", "NYAMBITI", "SUMVE", "WALLA"],
    Kwimba:["BUPAMWA", "FUKALO", "HUNGUMALWA", "IGONGWA", "ILULA", "KIKUBIJI", "MHANDE", "MWAKILYAMBITI", "MWAMALA", "MWANG'HALANGA", "MWANKULWE", "NG'HUNDI", "NGUDU", "NYAMILAMA", "SHILEMBO"],
    Buchosa:["BANGWE", "BUGORO", "BUHAMA", "BUKOKWA", "BULYAHEKE", "BUPANDWA", "ILIGAMBA", "IRENZA", "KAFUNZO", "KALEBEZO", "KASISA", "KATWE", "KAZUNZU", "LUGATA", "LUHARANYONGA", "MAISOME", "NYAKALIRO", "NYAKASASA", "NYAKASUNGWA", "NYANZENDA", "NYEHUNGE"],
    Ilemela:["BUGOGWA", "BUSWELU", "BUZURUGA", "IBUNGILO", "ILEMELA", "KAHAMA", "KAWEKAMANO", "KAYENZE", "KIRUMBA", "KISEKE", "KITANGIRI", "MECCO", "NYAKATO", "NYAMANORO", "NYAMHONGOLO", "NYASAKA", "PASIANSI", "SANGABUYE", "SHIBULA"],
    Nyamagana:["KISHILI", "BUHONGWA", "BUTIMBA", "IGOGO", "ISAMILO", "LUCHELELE", "LWANHIMA", "MABATINI", "MAHINA", "MBUGANI", "MHANDU", "MIRONGO", "MKOLANI", "MKUYUNI", "NYAMAGANA", "NYEGEZI", "PAMBA"],
    Tandahimba:["CHAUME", "CHIKONGOLA", "CHINGUNGWE", "DINDUMA", "KITAMA I", "KWANYAMA", "LITEHU", "LUAGALA", "LUKOKODA", "LYENJE", "MAHUTA", "MALOPOKELO", "MAUNDO", "MCHICHIRA", "MDIMBA MYOMA", "MICHENJELE", "MIHAMBWE", "MILONGODI", "MIUTA", "MKONJOWANO", "MKOREHA", "MKUNDI", "MKWEDU", "MKWITI", "MNDUMBWE", "MNYAWA", "NAMBAHU", "NAMIKUPA", "NANHYANGA", "NAPUTA", "NGUNJA", "TANDAHIMBA"],
    Newala_vijijini:["CHIHANGU", "CHILANGALA", "CHITEKETE", "CHIWONGA", "KITANGARI", "MAKUKWE", "MALATU", "MAPUTI", "MCHEMO", "MDIMBA MPELEPELE", "MIKUMBI", "MKOMA II", "MKWEDU", "MNYAMBE", "MNYEU", "MPWAPWA", "MTOPWA", "MTUNGURU", "MUUNGANO", "NAKAHAKO", "NAMBALI", "NANDWAHI"],
    Nanyumbu:["CHIPUPUTA", "KAMUNDI", "KILIMANIHEWA", "LIKOKONA", "LUMESULE", "MANGAKA", "MARATANI", "MASUGURU", "MICHIGA", "MIKANGAULAYA", "MKONONA", "MNANJE", "NANDETE", "NANGOMBA", "NANYUMBU", "NAPACHO", "SENGENYA"],
    Mtwara_vijijini:["DIHIMBA", "KITERE", "LIBOBE", "LIPWIDI", "MADIMBA", "MAHURUNGA", "MANGOPACHANNE", "MAYANGA", "MBAWALA", "MKUNWA", "MOMA", "MPARURA", "MSANGAMKUU", "MSIMBATI", "MUUNGANO", "NALINGU", "NANGURUWE", "NAUMBU", "NDUMBWE", "TANGAZO", "ZIWANI"],
    Ndanda:["CHIGUGU", "CHIKUKWE", "CHIKUNDI", "CHIKUNJA", "CHIWALE", "CHIWATA", "LUKULEDI", "MLINGULA", "MPANYANI", "MSIKISI", "MWEMA", "NAMAJANI", "NAMATUTWE", "NANGANGA", "NANGOO", "NDANDA"],
    Lulindi:["CHIKIROPOLA", "CHIUNGUTWA", "LIPUMBURU", "LULINDI", "LUPASO", "MAKONG'ONDA", "MBUYUNI", "MCHAURU", "MIJELEJELE", "MITESA", "MKULULU", "MNAVIRA", "MPETA", "MPINDIMBI", "NAMALENGA", "NAMWANGA", "SANNJOTA", "SINDANO"],
    Newala_mjini:["JULIA", "LUCHINGU", "MAHUMBIKA", "MAKONGA", "MAKOTE", "MCHOLI I", "MCHOLI II", "MKULUNG'ULU", "MKUNYA", "MNEKACHI", "MTONYA", "MTUMACHI", "NAMINYONGA", "NANGURUWE", "NANGWALA", "TULINDANE"],
    Nanyamba:["CHAWI", "DINYECHA", "HINJU", "HIROMBA", "KITAYA", "MBEMBALEO", "MILANGOMINNE", "MNIMA", "MNONGODI", "MTIMBILIMBWI", "MTINIKO", "NAMTUMBUKA", "NANYAMBA", "NITEKELA", "NJENGWA", "NYUNDO"],
    Masasi_mjini:["CHANIKANGUO", "JIDA", "MARIKA", "MATAWALE", "MIGONGO", "MKOMAINDO", "MKUTI", "MTANDI", "MUMBAKA", "MWENGE MTAPIKA", "NAPUPA", "NYASA", "SULULU", "TEMEKE"],
    Mtwara_mjini:["CHIKONGOLA", "CHUNO", "JANGWANI", "KISUNGULE", "LIKOMBE", "MAGENGENI", "MAGOMENI", "MAJENGO", "MTAWANYA", "MTONOYA", "NALIENDELE", "RAHALEO", "RELI", "SHANGANI", "TANDIKA", "UFUKONI", "VIGAENI"],
    Ulanga:["CHIROMBOLA", "EUGA", "ILONGA", "IRAGUA", "ISONGO", "KETAKETA", "KICHANGANI", "LUKANDE", "LUPIRO", "MAHENGE MJINI", "MAWASILIANO", "MBUGA", "MILOLA", "MINEPA", "MSOGEZI", 'MWAYA', "NAWENGE", "RUAHA", "SALI", "UPONERA", "VIGOI"],
    Mvomero:["BUNDUKI", "DAKAWA", "DIONGOYA", "DOMA", "HEMBETI", "HOMBOZA", "KANGA", "KIBATI", "KIKEO", "KINDA", "KWEUMA", "LANGALI", "LUALE", "LUBUNGO", "MANGAE", "MASKATI", "MELELA", "MGETA", "MHONDA", "MKINDO", "MLALI", "MSONGOZI", "MTIBWA", "MVOMEO", "MZIHA", "MZUMBE", "NYANDIRA", "PEMBA", "SUNGAJI", "TCHENZEMA"],
    Morogoro_kusini_mashariki:["GWATA", "KIBUKO", "MATUU", "MIKESE", "MKAMBALANI", "MKULAZI", "MKUYUNI", "NGERENGERE", "TEGERERO", "TOMONDO", "TUNUNGUO"],
    Morogoro_kusini:["BUNGU", "BWAKIRA CHINI", "BWAKIRA JUU", "KASANGA", "KIBOGWA", "KIBUNGO", "KISAKI", "KISEMU", "KOLERO", "KONDE", "LUNDI", "MNGAZI", "MRTOMBOZI", "MVUHA", "SELEMBALA", "SINGISA", "TAWA"],
    Mlimba:["CHING'ANDA", "CHISANO", "CHITA", "IDETE", "IGIMA", "KALENGAKELU", "KAMWENE", "MASAGATI", "MBINGU", "MCHOMBE", "MLIMBA", "MNGETA", "MOFU", "NAMWAWALA", "UCHINDILE", "UTENGULE" ],
    Malinyi:["BIRO", "IGAWA", "ITETE", "KILOSA MPEPO", "MALNYI", "MTIMBIRA", "NGOHERANGA", "NJIWA", "SOFI", "USANGULE"],
    Mikumi:["KIDODI", "KILANGALI", "KISANGA", "MAMBWEREBWERE", "MALOLO", "MASANZE", "MHENDA", "MIKUMI", "RUAHA", "RUHEMBE", "TINGIGA", "ULELING'OMBE", "VIDUNDA", "ZOMBO"],
    Kilosa:["BEREGA", "CHANZURU", "DUMILA", "KASIKI", "KIDETE", "KIMAMBA A", "KIMAMBA B", "KITETE", "LUMBIJI", "LUMUMA", "MABULA", "MADOTO", "MAGOLR", "MAGOMENI", "MAGUBIKE", "MAGUHA", "MAMBOYA", "MBIGIRI", "MBUMI", "MKWATANI", "MSOWERO", "MTUMBATU", "MVUMI", "PARAKUYO", "RUDEWA" ],
    Gairo:["CHAGONGWE", "CHAKWALE", "CHANJALE", "CHIGELA", "GAIRO", "IDIBO", "ITALAGWE", "IYOGWE", "KIBEDYA", "LESHATA", "MADEGE", "MAGOWEKO", "MANDEGE", "MKALAMA", "MSINGISI", "NONGWE", "RUBEHO", "UKWAMANI"],
    Kilombero:["IFAKARA", "KATINDIUKA", "KIBAONI", "KIBEREGE", "KIDATU", "KISAWASAWA", "LIPANGALALA", "LUMEMO", "MANG'ULA", "MANG'ULA B", "MBASA", "MICHENGA", "MKULA", "MLABANI", "MSOLWA STATION", "MWAYA", "SANJE", "SIGNAL", "VIWANJA SITINI"],
    Morogoro_mjini:["BIGWA", "BOMA", "CHAMWINO", "KAUZENI", "KICHANGANI", "KIHONDA", "KIHONDA MAGHOROFANI", "KILAKALA", "KINGO", "KINGOLWIRA", "KIWANJA CHA NDEGE", "LUHUNGO", "LUKOE", "MAFIGA", "MAFISA", "MAGADU", "MAZIMBU", "MBUYUNI", "MINDU", "MJI MKUU", "MJI MPYA", "MKUNDI", "MLIMANI", "MWEMBESONGO", "MZINGA", "SABASABA", "SULTANI AREA", "TUNGI", "UWANJA WA TAIFA"],
    Rungwe:["BAGAMOYO", "BUJELA", "BULYAGA", "IBIGHI", "IKUTI", "ILIMA", "IPONJOLA", "ISONGOLE", "ITAGATA", "KAWETELE", "KINYALA", "KISIBA", "KISONDELA", "KIWIRA", "KYIMO", "LUFINGO", "LUPEPO", "MAKANDANA", "MALINDO", "MASEBE", "MASOKO", "MASUKULU", "MATWEBE", "MPUGUSO", "MSASANI", "NDANTO", "NKUNGA", "SUMA", "SWAYA"],
    Mbeya_vijijini:["BONDE LA SONGWE", "IGALE", "IGOMA", "IHANGO", "IJOMBE", 'IKUKWA', "ILEMBO", "ILUNGU", "INYALA", "ISUTO", 'ITAWA', "ITEWE", "IWIJI", "IWINDI", "IYUNGA MAPINDUZI", "IZYRA", "LWANJIRO", "MAENDELEO", "MASOKO", "MJELE", "MSHEWE", "NSALALA", "SANTILYA", "SHIZUYI", "SWAYA", "TEMBELA", "ULENJE", "UTENGULE/USONGWE"],
    Mbarali:["CHIMALA", "IGAVA", "IGURUSI", "IHAHI", "IMALLO SONGWE", "IPWANI", "ITAMBOLEO", "KONGOLO", "LUGELELE", "LUHANGA", "MADIBIRA", "MAHONGOLE", "MAPOGORO", "MAWINDI", "MIYOMBWENI", "MWATENGA", "RUIWA", "RUJEWA", "UBARUKU", "UTENGULE USANGU"],
    Kyela:["BONDENI", "BUJONDE", "BUSALE", "IBANDA", "IKAMA", "IKIMBA", "IKOLO", "IPANDE", "IPINDA", "IPYANA", "ITOPE", "ITUNGE", "KAJUNJUMELE", "KATUMBAONGWE", "KYELA", "LUSUNGO", "MABABU", "MAKWALE", "MATEMA", "MBUGANI", "MIKOROSHONI", "MUUNGANO", "MWANGANYANGA", "MWAYA", "NDANDALO", "NDOBO", "NGANA", "NGONGA", "NJISI", "NKOKWA", "NKUYU", "SEENGETI", "TALATALA"],
    Lupa:["BWAWANI", "CHALANGWA", "CHOKAA", "IFUMBO", "ITEWE", "KAMBIKATOTO", "KASANGA", "LUALAJE", "LUPA", "MAFYEKO", "MAKONGOLOSI", "MAMBA", "MATUNDASI", "MATWIGA", "MBUGANI", "MKOLA", "MTANILA", "NKUNG'UNGU", "SANGAMBI", "UPENDO"],
    Bisokelo:["ISANGE", "ITETE", "KABULA", "KAMBASEGELA", "KANDETE", "KISEGESE", "LUFILYO", "LUPATA", "LUTEBA", "LWANGWA", "MPATA", "MPOMBO", "NTABA"],
     Mbeya_mjini:["FOREST", "GHANA", "IDUDA", "IGANJO", "IGANZO", "IGAWILO", "ILEMI", "ILOMBA", "ISANGA", "ISYESYE", "ITAGANO", "ITENDE", "ITEZI", "ITIJI", "IWAMBI", "IYELA", "IYUNGA", "IZIWA", "KALOBE", "MAANGA", "MABATINI", "MAENDELEO", "MAJENGO", "MBALIZI ROAD", "MWAKIBETE", "MWASANGA", "MWASENKWA", "NONDE", "NSALADA", "NSOHO", "NZOVWE", "RUANDA", "SINDE", "SISIMBA", "TEMBELA", "UYOLE"],
    Tarime_vijijini:["BINAGI", "BUMERA", "GANYANGE", "GORONGA", "GWITIRYO", "ITIRYO", "KEMAMBO", "KIBASUKA", 'KIORE', "KOMASWA", 'KWIHANCHA', "MANGA", "MATONGO", "MBOGI", "MURIBA", 'MWEMA', "MYAKONGA", "NYAMWAGA", "NYANSINCHA", "NYANUNGU","NYARERO", "NYOROKOBA", 'PEMBA', "REGICHERI", "SIRARI", "SUSUNI"],
    Serengeti:["BUSAWE", "GEITASAMO", "IKOMA", "ISSENYE", "KEBANCHABANCHA", "KENYAMONTA", "KISAKA", "KISANGURA", "KYAMBAHI", "MACHOCHWE", "MAGANGE", "MAJIMOTO", "MANCHIRA", "MATARE", "MBALIBALI", "MOROTONGA", "MOSOMGO", "MUGUMU", "NAGUSI", "NATTA", "NYAMATARE", "NYAMBURETI", "NYAMOKO", "NYANSURURA", "RIGICHA", "RING'WAVI", "RUNG'ABUE", "SEDEKO", "STENDI KUU", "UWANJA WA NDEGE"],
    Rorya:["BARAKI", "BUKURA", "BUKWE", "GORIBE", "IKOMA", "KIGUNGA", "KINYENCHE", "KIROGO", "KISUMWA", 'KITEMBE', "KOMUGE", "KORYO", "KYANG'OMBE", "KYANGASAGA", "MIRARE", "MKOMA", "NYABURONGO", "NYAHONGO", "NYAMAGARO", "NYAMTINGA", "NYAMUNGA", "NYATHOROGO", "RABOUR", "RARANYA", "ROCHE", "TAI"],
    Musoma_vijijini:["BUGODI", "BUGWEMA", "BUKIMA", "BUKUMI", 'BULINGA', "BUSAMBARA", 'BWASI', "ETARO", "IFULIFU", "KIRIBA", "MAKOJO", "MUSANJA", "MUGANDO", "MURANGI", "NYAKATENDE", "NYAMBONO", "NYAMRANDIRIRA", "NYEGINA", "RUSOLI", "SUGUTI", "TEGERUKA"],
    Butiama:["BISUMWA", "BUHEMBA", 'BUKABWA', "BURUMA", "BUSEGWE", "BUSWAHILI", 'BUTIAMA', 'BUTUGURI', "BWIREGI", "KAMUGEGI", "KUKIRANGO", "KYANYARI", "MASABA", "MIRWA", "MURIAZA", "NYAMIMANGE", "NYANKANGA", "SIRORISIMBA"],
    Mwibara:["BUTIMBA", "CHITENGULE", "IGUNDU", "IRAMBA", "KASUGUTI", "KIBARA", "KISORYA", "NAMHULA", "NAMPINDI", "NANSIMO", "NERUMA", "NYAMIHYORO"],
    Bunda:["HUNYARI", "KETARE", "MIHINGO", "MUGETA", "NYAMANGUTA", "NYAMUSWA", "SALAMA"],
    Tarime_mjini:["BOOMANI", "KENYAMANYORI", "KETARE", "NKENDE", "NYAMISANGURA", "NYANDOTO", "SABASABA", "TURWA"],
    Bunda_mjini:["BALILI", "BUNDA MJINI", 'BUNDA STOO', "GUTA", "KABARIMU", "KABASA", "KUNZUGU", "MANYAMANYAMA", "MCHARO" ,"NYAMAKOKOTO", "NYASURA", "NYATWALI", "SAZIRA", "WARKU"],
    Musoma_mjini:["BUHARE", "BWERI", "IRINGO", "KAMUNYONGE", "KIGERA", 'KITAJI', "KWANGWA", 'MAKOKO', 'MSHIKAMANO', "MUKENDO", "MWIGOBERO", "MWISENGE", "NYASHO", "RWAMLIMI"],
    Simanjiro:["EMBOREET", "ENDIAMTU", "ENDONYOENGIJAPE", "KITWAI", "KOMOLO", "LANGAI", "LOIBORIRET", "LOIBORSOIT", "MERERANI", "MSITU WA TEMBO", "NABERERA", "NAISINYAI", "NGORIKA", "OIJORO NA 5", "ORKESUMET", "RUVU REMIT", "SHAMBARAI", "TERRAT"],
    Mbulu_vijijini:["BASHAY", "DIAMU", "DONGOBESH", "ENDAHAGICHAN", "ESHKESH", "GETERER", "GIDHI", "HAYDARER", "HAYDOM", "LABAY", "MAGHANG", "MARETADU", "MASIEDA", "MASQARODA", "TUMATI", "YAEDA AMPA", "YAEDA CHINI" ],
    Kiteto:["BWAGAMOYO", "BWAWANI", "CHAPAKAZI", "DONGO", "DOSIDOSI", "ENGUSERO", "KALOLENI", 'KIBAYA', "KIJUNGU", "LAISERI", "LENGATEI", "LOOLERA", "MAGUNGU", "MAKAME", "MATUI", "NAMELOCK", "NDEDO", "NDIRIGISH", "NJORO", "OLBOLOTI", "PARTIMBO", 'SONGAMBELE', "SUNYA"],
    Hanang:["BALANGDALALU", "BASSODESH", "BASSOTU", "DAWAR", "DIRMA", "DUMBETA", "ENDAGAW", 'ENDASAL', "ENDASIWOLD", "GANANA", "GARAWJA", "GEHANDU", "GENDABI", "GETANUWAS", "GIDAHABABIEG", "GISAMBALANG", "GITTING", "HIDET", "HIRBADAW", "ISHPONGA", "JORODOM", 'KATESH', "LAGHANGA", "LALAJI", "MASAKTA", "MASQARODA", "MEASKRON", "MOGITU", "MULBADAW", "NANGWA", "SIMBAY", "SIROP", 'WARETA'],
    Bababati_vijijini:["ARRI", "AYLAGAYA", "AYASANDA", "BASHNET", "BOAY", "DAREDA", "DURU", "ENDAKISO", "GALLAPO", "GIDAS", "KIRU", "KISANGAJI", "MADUNGA", "MAGARA", "MAGUGU", "MAMIRE", "MWADA","NAR", "NKAITI", "QAMEYU", "QASH", "RIRODA", "SECHEDA", "UFANA"],
    Mbulu_mjini:["AYAMAAMI", "AYAMONE", "BAGISH", "DAUDI", "ENDAGIKOT", "GEHANDU", "GUNYORA", "IMBORU", "KAINAM", "MARANG", "MURRAY", "NAHASEY", "NAMBIS", "SANU BARAY", "SILALODA", "TLAWI", "UHURU"],
    Babati_mjini:["BABATI", "BAGARA", "BONGA", "MAISAKA", "MUTUKA", "NANGARA", "SIGINO", "SINGR"],
    Ruangwa:["CHIBULA", "CHIENJELE", "CHINONGWE", "CHUNYU", "LIKUNJA", "LUCHELEGWA", "MAKANJIRO", "MALOLO", "MANDARAWE", "MANDAWA", "MATAMBARALE", "MBEKENYERA", "MBWEMKURU", "MNACHO", "NACHINGWEA", "NAMBILANJE", "NAMICHIGA", "NANDAGALA", "NANGANGA", "NARUNGOMBE", "NKOWE", "RUANGWA"],
    Nachingwea:["BOMA", "CHIOLA", 'CHIUMBATI SHULENI', "KIEGEI", "KILIMANIHEWA", "KILIMARONDO", "KIPARA MNERO", "KIPARA MTUA", "LIONJA", "MARAMBO", "MATEKWE", "MBONDO", "MCHONDA", "MITUMBATI", "MKOKA", "MKOTOKUYANA", "MNERO NGONGO", "MPIRUKA", "MTUA", "NACHINGWEA MJINI", "NAIPANGA", "NAIPINGO", "NAMAPWIA", "NAMATULA", "NAMBAMBO", "NAMIKANGO", "NANG'ONDO", "NANGOWE", "NDITI", "NDOMONI", "NGUNICHILE", "RAHA LEO", "RUPONDA", "STESHEMI", "UGAWAJI"],
    Mtama:["CHIPONDA", "KIWALALA", "LONGA", "MAJENGO", "MANDWANGA", "MNARA", "MNOLELA", "MTAMA", "MTUA", "MTUMBYA", "NACHUNYU", "NAHUKAHUKA", "NAMANGALE", "NAMUPA", "NAVANGA", "NYANGAMARA", "NYANGAO", "NYENGEDI", "PANGATENA", "SUDI"],
    Liwale:["BAIKIWA", "KIANGARA", "KIBUTUKA", "KICHONDA", "KIMAMBI", "LIKONGOWELE", "LILOMBE", "LIWALE B", "LIWALE MJINI", "MAKATA", "MANGIRIKITI", "MBAYA", "MIHUMO", "MIRUI", "MKUTANO", "MLEMBWE", "MPIGAMITI", "NANGANDO", "NANGANO", "NGONGOWELE"],
    Kilwa_kusini:["KIKOLE", "KIRANJERANJE", "KIVINJE", "UHIMALYAO", "LIKAWAGE", "MANDAWA", "MASOKO", "NANJIRINJI", "PANDE", "SONGOSONGO"],
    Kilwa_kaskazini:["CHUMO", "KANDAWALE", "KIBATA", "KINJUMBI", "KIPATIMU", "MIGURUWE", "MINGUMBI", "MITEJA", "MITOLE", "NAMAYUNI", "NJINJO", "SOMANGA", "TINGI"],
    Mchinga:["KILANGALA", "KILOLAMBWANI", "KITOMANGA", "KIWAWA", "MATIMBA", "MCHINGA", "MILOLA", "MIPINGO", "MVULENI", "NANGARU", "RUTAMBA"],
    Lindi_mjini:["CHIKONJI", "JAMHURI", "KITUMBIKWELA", "MAKONDE", "MATOPENI", "MBANJA", "MIKUMBI", "MINGOYO", "MITANDI", "MNAZI MMOJA", "MSINJAHILI", "MTANDA", "MWENGE", "NACHINGWEA", "NDORO", "NG'APA", "RAHALEO", "RASBURA", "TANDANGONGORO", "WAILES"],
    Siha:["BIRIRI", "DONYOMURWAK", "GARARAGUA", "IVARNY", "KARANSI", "KASHASHI", "KIRUA", "LIVISHI", "MAKIWARU", "MITI MIREFU", "NASAI", "NDUMETI", "NGARENAIROBI", "OLKOLILI", "ORMELILI", "SANYA JUU", "SONGU"],
    Same_mashariki:["BENDERA", "BOMBO", "BWAMBO", "KALEMAWE", "KIHURIO", "LUGULU", "MAORE", "MPINJI", "MTII", "MYAMBA", "NDUNGU", "VUJE", "VUNTA"],
    Same_magharibi:["BANGALALA", "CHOME", "GAVAO-SAWENI", "HEDARU", "KISIMA", "KISIWANI", "MABILIONI", "MAKANYA", "MHEZI", "MSHEWA", "MSINDO", "MWEMBE", "NJORO", "RUVU", "SAME", "STESHENI", "SUJI", "TAE", "VUDEE", "VUMARI"],
    Rombo:["ALENI", "CHALA", "HOLILI", "KATANGAA/MRERE", "KELAMFUA/MOKALA", "KINGACHI", "KIRONGO SAMANGA", 'KIRWA/KENI', "KISALE MSARANGA", "KITIRIMA", 'MAHIDA', "MAKIIDI", "MAMSERA", "MANDA", "MAARANGU KITOWO", "MENGENI", "MENGWE", "MOTAMBURU KITENDENI", "MRAO KERYO", "NANJARA", "NGOYONI", "OLELE", "REHA", "SHIMBI", "SHIMBI KWANDELE", "TARAKEA MOTAMBURU", "UBETU KAHE", "USHIRI/IKUINI"],
    Mwanga:["CHOMVU", "JIPE", "KIFULA", "KIGHARE", "KIGONIGONI", "KILEO", "KILOMENI", "KIRONGWE", "KIRYA", "KIVISINI", "KWAKOA", "LANG'ATA", "LEMBENI", "MGAGAO", "MSANGENI", "MWANGA", "MWANIKO", "NGUJINI", "SHIGHATINI", "TOLOHA"],
    Vunjo:["KAHE MAGHARIBI", "KAHE MASHARIKI", "KILEMA KASKAZINI", "KILEMA KATI", "KILEMA KUSINI", "KIRUA VUNJO KUSINI", "KIRUA VUNJO MAGHARIBI", "KIRUA VUNJO MASHARIKI", "MARANGU MASHARIKI", "MWIKA KASKAZINI", "MWIKA KUSINI", "NJIA PANDA"],
    Moshi_vijijini:["ARUSHA CHINI", "KIBOSHO KATI", "KIBOSHO MAGHARIBI", "KIBOSHO MASHARIKI", "KIMOCHI", "KINDI", "KIBOSHO KIRIMA", "MABOGINI", "MBOKOMU", "KIBOSHO OKAONI", "OLD MOSHIMAGHARIBI", "OLD MOSHI MASHARIKI", "URU KASKAZINI", "URU KUSINI", "URU SHIMBWE"],
    Hai:["BOMANG'OMBE", "BONDENI", "KIA", "MACHAME KASKAZINI", "MACHAME MAGHARIBI", "MACHAME MASHARIKI", "MACHAME NARUMU", "MACHAME UROKI", "MASAMA KATI", "MASAMA KUSINI", "MASAMA MAGHARIBI", "MASAMA MASHARIKI", "MASAMA RUNDUGAI", "MNADANI", "MUUNGANO", "ROMU", "WERUWERU"],
    Moshi_mjini:["BOMAMBUZI", "BONDENI", "KALOLENI", "KARANGA", "KIBORLONI", "KILIMANJARO", "KIUSA", "KORONGONI", "LONGUO B", "MAJENGO", "MAWENZI", "MFUMUNI", "MIEMBENI", "MJI MPYA", "MSARANGA", "NG'AMBO", "NJORO", "PASUA", "RAU", "SHIRIMATUNDA", "SOWETO"],
    Kigoma_kusini:["BASANZA", "BUHINGU", "HEREMBE", "IGALULA" , "ILAGALA", "ITEBULA", "KALYA", "KANDAGA", "KAZURAMIMBA", "MGANZA","MTEGOWANOTI", "MWAKIZEGA", "NGURUKA", "SIGUNGA", "SUNUKA", "UVINZA"],
    Kigoma_kaskazini:["BITALE", "KAGONGO", "KAGUNGA", "KALINZI", "KIDAHWE", "MAHEMBE", "MATENDO", "MKIGO", "MKONGORO", "MUGONYA", "MWAMGONGO", "MUNGONYA", "MWAMGONGO", "MWANDIGA", "NKUNGWE", "NYARUBANDA", "SIMBO", "ZIWANI"],
    Muhambwe:["BITARE", "BITURANA", "BUNYAMBO", "BUSAGARA", "BUSUNZU", "ITABA", "KAGEZI", "KIBONDO MJINI", "KITAHAMA", "KIZAZI", "KUMSENGA", "KUMWAMBU", "MABAMBA", "MISEZERO", "MUKABUYE", "MURUNGU", "NYARUYOBA", "RUGONGWE", "RUHOSOKO"],
    Kasulu_vijijini:["ASANTE NYERERE", "BUGAGA", "BUHOGO", "HERU USHINGO", "KAGERA NKANDA", "KALELA", "KIGEMBE", "KITANGA", "KURUGONGO", "KWAGA", "MAKERE", "MUZYE", "NYACHENDA", "NYAKITONTO", "NYAMIDAHO", "NYAMUNYUSI", "RUGWE MPYA", "RUSESA", "SHUNGULIBA", "TITYE"],
    Buyungu:["GWANUMPU", "GWARAMA", "KAKONKO", "KANYONZA", "KASANDA", "KASUDA", "KATANGA", "KIZIGUZIGU", "MUGUNZU", "MUHANGE", "NYABIBUYE", "NYAMTUKUZA", "RUGENGE"],
    Buhigwe:["BIHARU", "BUHIGWE", "BUKUBA", "JANDA", "KAJANA", "KIBANDE", "KIBWIGWA", "KILELEMA", "KINAZI", "MKATANGA", "MUBANGA", "MUGERA", "MUHINDA", "MUNANILA", "MUNYEGERA", "MUNZEZE", "MUYAMA", "MWAYAYA", "NYAMUGALI", "RUSABA"],
    Kasulu_mjini:["HERU JUU", "KIGONDO", "KIMOBWA", "KUMSENGA", "KUMUNYIKA", "MUGANZA", "MUHUNGA", "MURUBONA", "MURUFITI", "MURUSI", "MSAMBARA", "MWILAVYA", "NYANSHA", "NYUMBIGWA", "RUHITA"],
    Kigoma_mjini:["BANGWE", "BUHANDA", "BUSINDE", "BUZEBAZEBA", "GUNGU", "KAGERA", "KASIMBU", "KASINGIRIMA", "KATUBUKA", "KIBIRIZI", "KIGOMA", "KIPAMBA", "KITONGONI", 'MACHINJIONI', "MAJENGO", "MWANGA KASKAZINI", "MWANGA KUSINI", "RUBUGA", "RUSIMBI"],
    Nsimbo:["IBINDI", "ITENKA", "KANOGE", "KAPALALA", "KATUMBA", "LITAPUNGA", "MACHIMBONI", "MTAPENDA", "NSIMBO", "SITALIKE", "UGALLA", "URUWIRA"],
    Kavuu:["CHAMALENDI", "IKUBA", "KASANSA", "KIBAONI", "MAJIMOTO", "MAMBA", "MWAMAPULI", "USEVYA"],
    Katavi:["ILELA", "ILUNDE", "INYONGA", "KAMSISI", "NSENKWA", "UTENDE", "BULAMATA", "IKOLA", "ILANGU", "IPWAGA", "ISENGULE", "KABUNGU", "KAPALAMSENGA", "KAREMA", "KASEKESE", "KATUMA", "MISHAMO", "MNYAGALA", "MPANDANDOGO", "MWESE", "SIBWESA", "TONGWE"],
    Mpanda_mjini: ["ILEMBO", "KAKESE", "KASHAULILI", "KASOKOLA", "KAWAJENSE", "KAZIMA", "MAGAMBA", "MAJENGO", "MAKANYAGIO", "MISUNKULIMO", "MPANDA HOTEL", "MWAMKULU", "NSEMULWA", "SHANWE", "UWANJA WA NDEGE"],
    Ngara:["BUGARAMA", "BUKIRIRO", "KABANGA", "KANAZI", "KASULO", "KEZA", "KIBIMBA", "KIBOGORA", "KIRUSHYA", "MABAWE", "MBUBA", "MUGANZA", "MUGOMA", "MURUKULAZO", "MURUSAGAMBA", "NGARA MJINI", "NTOBEYE", "NYAKISASA", "NYAMANGOMA", "RULENGE", "RUSUMO"],
    Muleba_kusini:["BIIBABO", "BISHEKE", "BUGANGUZI", "BUHANGAZA", "BUREZA", "BURUNGURA", "GWANSELI", "IJUMBI", "IKONDO", "KUZA", "KABIRIZI", "KARAMBI", 'KASHARUNGA', "KASHASHA", "KIBANGA", "KIMWANI", "KISHANDA", "KYEBITEMBE", "MAGATA/KARUTANGA", "MAZINGA", "MUBUNDA", "MULEBA", "NSHAMBA", "NYAKABANGO", "RULANDA"],
    Muleba_kaskazini:["BULYAKASHAJU", "BUMBIRE", "GOZIBA", "IBUGA", "IZIGO", "KAGOMA", "KAMACHUMU", "KATOKE", "KEREBE", "KIKUKU", "MAFUMBO", "MAYONDWE", "MUHUTWE", "MUSHABAGO", "NGENGE", "NYAKATANGA", "RUHANGA", "RUTORO"],
    Nkenge:["BUGANDIKA", "BUGORORA", "BUYANGO", "BWANJAI", "GERA", "ISHOZI", "ISHUNJU", "KAKUNYU", "KANYIGO", "KASSANBYA", "KASHENYE", "KALIMILILE", "KITOBO", "NYAKA", "MABALE", "MINZIRO", "MUSHASHA", "MUTUKULA", "NSUNGA", "RUZINGA" ],
    Kyerwa:["BUGARA", "BUGOMORA", "BUSINDE", "ISINGIRO", "ITEERA", "KAISHO", "KAKANJA", "KAMULI", "KIBARE", "KIBINGO", "KIKUKURU", "KIMULI", "KITWE", "KITWECHENKURA", "KYERWA", "MABIRA", "MURONGO", "NKWENDA", "NYAKATUNTU", "NYARUZUMURA", "RUKURAIJO", "RUTUNGURU", "RWABWERE", "SONGAMBELE"],
    Karagwe:["BUGENE", "BWERANYANGE", "CHANIKA", "CHONYONYO", "IGURWA", "IHANDA", "IHEMBE", "KAMAGAMBO", "KANONI", "KAYANGA", "KIBONDO", "KIHANGA", "KIRURUMA", "KITUNTU", "NDAMA", "NYABIYONZA", "NYAISHOZI", "NYAKABANGA", "NYAKAKIKA", "NYAKASIMBI", "RUGERA", "RUGU"],
    Bukoba_vijijini:["BEHENDAGABO", "BUGUJO", "BUTELANZKUZI", "IBWERA", "IZIMBYA", "KAAGYA", "KAIBANIA", "KANYEGEREKO", "KARABAGAINE", "KASHARU", "KATERERO", "KATOMA", "KATORO", "KEMONDO", "KIBIRIZI", "KIKOMELO", "KISHANJE", "KISHOGO", "KYAITOKE", "KYAMULAILE", "MARUKU", "MIKONI", "MUGAJWALE", "NYAKATO", "NYAKIBIMBILI", "RUBAFU", "RUBALE", "RUHUNGA", "RUKOMA"],
    Biharamulo_magharibi:["BIHARAMULO MJINI", "BISIBO", "KABINDI", "KALENGE", "KANIHA", "KATAHOKA", "LUSAHUNGA", "NEMBA", "NYAKAHURA", "NYAMAHANGA", "NYAMIGOGO", "NYANTAKARA", "NYANZA", "NYARUBUNGO", "BUNAZI", "RUZIBA"],
    Bukoba_mjini:["BAKOBA", "BILELE", "BUHEMBE", "HAMUGEMBE", "IJUGANYONDO", "KAGONDO", "KAHORORO", "KASHAI", "KIBETA", "KITENDAGURO", "MIEMBENI", "NSHAMBYA", "NYANGA", "RWAMISHENYE"],
    Mufindi_kusini:["IDETE", "IDUNDA", "IGOWOLE", "IHOWANZA", "ITANDULA", "KASANGA", "KIYOWELLA", "LUHUNGA", "MADUMA", "MAKUNGU", "MALANGALI", "MBALAMAZIWA", "MINGA", "MTAMBULA", "MTWANGO", "NYOLOLO"],
    Mufindi_kaskazini:["IFWAGI", "IGOMBAVANU", "IHALIMBA", "IHANU", "IKONGOSI", "IKWEHA", "KIBENGU", "MAPANDA", "MDABULO", "MPANGATAZARA", "SADANI"],
    Kilolo:["BOMA LA NG'OMBE", "DABAGA", "IBUMU", "IDETE", "IHIMBO", "ILULA", "IMAGE", "IROLE", "KIMALA", "KISING'A", "LUGALO", "MAHENGE", "MASISIWE", "MLAFU", "MTITU", "NG'ANG'ANGE", "NG'URUHE", "NYALUMBU", "NYANZWA", "RUAHA MBUYUNI", "UDEKWA", "UHAMBINGETO", "UKUMBI", "UKWEGA"],
    Kalenga:["IFUNDA", "KALENGA", "KIHANGA", "KIWERE", "LUHOTA", "LUMULI", "LYAMGUNGWE", "MABOGA", "MAGULILWA", "MASAKA", "MGAMA", "MSEKE", "NZIHI", "ULANDA", "WASA"],
    Ismani:["IDODI", "ILOLO MPYA", "ITUNUNDU", "IZAZI", "KOHOROGOTA", "KISING'A", "MAHUNINGA", "MALENGAMAKALI", "MBOLIMBOLI", "MIGOLI", "MLENGE", "MLOWA", "NYANG'ORO"],
    Mafinga_mjini:["BOMA", "BUMILAYINGA", "CHANGARAWE", "ISALAVANU", "KINYANAMBO", "RUNGEMBA", "SAO HILL", "UPENDO", "WAMBI"],
    Iringa_mjini :["GANGILONGA", "IGUMBILO", "ILALA", "ISAKALILO", "KIHESA" , "KITANZINI", "KITWIRU", "KWAKILOSA", "MAKORONGONI", "MIVINJENI", "MKIMBIZI", "MKWAWA", "MLANDEGE", "MSHINDO", "MTWIVILA", "MWANGATA", "NDULI", "RUAHA"],
    Mbogwe:["BUKANDWE", "BUNIGONZI", "IKOBE", "IKUNGUIGAZI", "ILOLANGULU", "IPONYA", "ISEBYA", "LUGUNGA", "LULEMBELA", "MASUBWE", "MBOGWE", "NANDA", "NGEMO", "NHOMOLWA", "NYAKAFURU", "NYASATO", "USHIRIKA"],
    Geita:["BUGALAMA", "BUGULULA", "ISULWABUTUNDWE", "IZUMACHELI", "KAGU", "KAKUBILO", "KAMHANGA", "KATOMA", "LUBANGA", "LWENZERA", "NKOME", "NYAMBOGE", "NYAWILIMILWA", "NZERA", "SENGA"],
    Busanda:["BUJULA", "BUKOLI", "BUKONDO", "BUSANDA", "BUTOBELA", "BUTUNDWE", "CHIGUNDA", "KAMENA", "KASEME", "KATORO", "LUDETE", "MAGENGE", "NYACHILULUMA", "NYAKAGOMBA", "NYAKAMWAGA", "NYALWANZANA", "NYAMALINDE", "NYAMIGOTA", "NYAMWILOLELWA", "NYARUGUSU", "NYARUYEYE", "RWAMGASA"],
    Chato:["BUKOME", "BUSERESERE", "BUTENGORUMASA", "BUZIKU", "BWANGA", "BWERA", "BWONGERA", "BWINA", "BWONGERA", "CHATO", "ICHWANKIMA", "ILEMELA", "ILYAMCHELE", "IPARAMASA", "KACHWAMBA", "KASENGA", "LATENDE", "KOGONGO", "MAKURUGUSI", "MINKOTO", "MUGANZA", "MUUNGANO", "NYAMIREMBE", "NYARUTEMBO"],
    Bukombe:["BUGELENGA", "BUKOMBE", "BULANGWA", "BULEGA", "BUSONYO", "BUTINZYA", "IGULWA", "IYOGELO", "KATENTE", "KATOME", "LYAMBAMGONGO", "NAMONGE", "NG'ANZO", "RUNZEWE MAGHARIBI", "RUNZEWE MASHARIKI", "USHIROMBO", "UYOVU"],
    Nyanghwale:["BUKWIMBA", "BUSOLWA", "IZUNYA", "KABOHA" , "KAFITA", "KAKORA", "KHARUMWA", "MWINGIRO", "NUNDU", "NYABULANDA", "NYAMTUKUZA", "NYANG'HWALE", "MYIJUNDU", "NYUNGWA", "SHABAKA"],
    Geita_mjini:["BOMBAMBILI", "BUHALAHALA", "BULELA", "BUNG'WANGOKO", "IHANAMILO", "KALANGALALA", "KANYALA", "KASAMWA", "MGUSU", "MTAKUJA", "NYANKUMBU", "SHILOLELI"],
    Mpwapwa: ["BEGREGE", "CHITEMO", "CHUNYU", "GODEGODE", "GULWE", "IWONDO", "KIMAGAI", "LUPETA", "MATOMONDO", "MAZAE", "MIMA", "MLEMBULE", "MPWAPWA MJINI", "MGHAMBI", "VING'HAWE"],
    Kibakwe:["CHIPOGORO", "GALIGALI", "IPERA", "KIBAKWE", "KINGITI", "LUFU", "LUHUNDWA", "LUMUMA", "MALOLO", "MANG'ALIZA", "MASSA", "MBUGA", "MLUNDUZI", "MTERA", "PWAGA", "RUDI", "WANGI", "WOTTA"],
    Kongwa:["CHAMKOROMA", "CHITEGO", "CHIWE", "HOGORO", "IDUO", "KIBAIGWA", "KONGWA", "LENJULU", "MAKAWA", "MATONGORO", "MKOKA", "MLALI", "MTANANA", "NG'HUMBI", "NGOMAI", "NJOGE", "PANDAMBILI", "SEGARA", "SEJELI", "SONGAMBELE", "UGOGONI", "ZOISSA"],
    Kondoa: ["BEREKO", "BUMBUTA", "BUSI", "CHANGAA", "HAUBI", "ITOLOLO", "KALAMBA", "KEIKEI", "KIKILO", "KIKORE", "KINYASI", "KISESE", "KWADELO", "MASANGE", "MNENIA", "PAHI", "SALANKA", "SOERA", "THAWI"],
    Chemba:["BABAYU", "CHANDAMA", "CHURUKU", "DALAI", "FARKWA", "GOIMA", "GWANDI", "JANGALO", "KIDOKA", "KIMAHA", "KINYAMSINDO", "KWAMTORO", "LAHODA", "LALTA", "MAKORONGO", "MONDO", "MPENDO", "MRIJO", "MSAADA", "OVADA", "PARANGA", "SENZAWA", "SONGOLO", "SOYA", "TUMBAKOSE"],
    Mvumi:["CHIBOLI", "CHINUGULU", "FUFU", "HANDALI", "HUZI", "IDIFU", "IGANDU", "IKOMBOLINGA", "IRINGA MVUMI", "LOJE", "MAKANG'WA", "MANDA", "MANZASE", "MLOWA BARABARANI", "MPWAYUNGU", "MUUNGANO", "MVUMI MAKULU", "MVUMI MISHENI", "NGHAHELEZI", "NGHAMBAKU", "NCHINHI"],
    Chamwino: ["BUIGIRI", "CHAMWINO", "CHILONWA", "DABALO", "HANETI", "IKOWA", "ITISO", "MAJELEKO", "MANCHALI", "MEMBE", "MSAMALO", "MSANGA", "SEGALA", "ZAJILIWA"],
    Bahi: ["BABAYU", "BAHI", "CHALI", "CHIBELELA", "CHIFUTUKA", "CHIKOLA", "CHIPANGA", "IBIHWA", "IBUGULE", "ILINDI", "KIGWE", "LAMAITI", "MAKANDA", "MPALANGA", "MPAMANTWA", "MPINGA", "MSISI", "MTITAA", "MUNDEMU", "MWITIKIRA", "NONDWA", "ZANKA"],
    Kondoa_mjini:["BOLISA", "CHEMCHEM", "KILIMANI", "KINGALE", "KOLO", "KONDOA MJINI", "SERYA", "SURUKE"],
    Dodoma_mjini: ["CHAHWA", "CHAMWINO", "CHANG'OMBE", "CHIGONGWE", "CHIHANGA", "DODOMA MAKULU", "HAZINA", "HOMBOLO BWAWANI", "IHUMWA", "IPAGALA", "IOALA", "IYUMBU", "KIKOMBO", "KIKUYU KASKAZINI", "KIKUYI KUSINI", "KILIMANI", "KIWANJA CHA NDEGE", "KIZOTA", "MADUKANI", "MAJENGO", "MAKOLE", "MAKUTUPORA", "NATUNBULU", "MBABALA", "MBALAWALA", "MIYUJI", "MKONZE", "MNADANI", "MPUNGUZI", "MSALATO", "MTUMBA", "NALA", "NGHONG'ONHA", "NKUHUNGU", "NTYUKA", "NZUGUNI", "TAMBUKA RELI", "VIWANDANI", "ZUZU"],
    Arumeru_Mashariki: [ "AKHERI", "AMBURENI", "IMBASENI", "KIKATITI", "KIKWE", "KINGORI", "LEGURUKI", "MAJENGO", "MAJI YA CHAI", "MAKIBA", "MALULA", "MARORONI", "MARUVANGO", "MBUGUNI", "NGABOBO", "NGARENANYUKI", "NKOANEKOLI", "NKOANRUA", "NKOARANGA", "NKOARISAMBU", "POLI", "SEELA SING'ISI", "SHAMBARAI BURKA", "SONGORO", "USA RIVER", "UWIRO"],
    Karatu: ["BARAY", "BUGER", "DAA", "ENDABASH", "ENDAMAGHANG", "ENDAMARARIEK", "GANAKO", "KANSAY", "KARATU", "MANG'OLA", "MBULUMBULU", "OLDEANI", "QURUS", "RHOTIA"],
    Mjini: ["BARAA", "DARAJANI", "ELERAI", "ENGUTOTO", "KATOLENI", "KATI", "KIMANDOLU", "LEMARA", "LEVOLOSI", "MOIVARO", "MOSHONO", "MURIET", "NGARENARO", "OLASITI", "OLMOTI", "OLOIRIEN", "OSUNYAI JR", "SAKINA", "SEKEI", "SINONI", "SOKONI I", "SOMBETINI", "TERRAT", "THEMI", "UNGALIMITED" ],
    Longido: ["ELANG'ATADAPASH", "GELAI LUMBWA", "GELAI MELRUGOI", "ILOIRIENITO", "KAMWANGA", "KETUMBEINE", "KIMOKOUWA", "LONGIDO", "MATALE A", "MUNDARARA", "NAMANGA", "NOONDOTO", "OLMOLOG", "ORBOMBA", "SINYA", "TINGATINGA"],
    Arumeru_Magharibi: ["BANGATA", "BWAWANI", "ILBORU", "ILKIDING'A", "KIMNYAKI", "KIRANYI", "KISONGO", "KISUTU", "LAROI", "MUSA", "MWANDET", "NGURUMA", 'OLDONYO-WAS', "OLDONYOSAMBU", "OLERIEN", "OLJORO", "OLMOTONYI", "OLTRUMET", "OLTUROTO", "SAMBASHA", "SOKONI II", "TARAKWA"], 
    Monduli: ["ENGARUKA", "ENGUTOTO", "ESILALEI", "LASHAINE", "LEMOOTI", "LEPURKO", "LOLKISALE", "MAJENGO", "MAKUYUNI", "MESERANI", "MFEREJI", "MIGUNGANI", "MOITA", "MONDULI JUU", "MONDULI MJINI", "MSWAKINI", "MTO WA MBU", "NAALARAMI", "SELELA", "SEPEKO"],
    Ngorongoro:["ALAILELAI", "ALAITOLEI", "ARASH", "DIGODIGO", "ENDULENI", "ENGARESERO", "ENGUSEROSAMBU", "EYASI", "KAKESIO", "KIRANGI", "MAALON", "MALAMBO", "MISIGIYO", "NAINOKANOA", "NAIYOBI", "NGOLE", "NGORONGORO", "OLBALBAL", "OLDONYOSAMBU", "OLOIPIRI", "OLOIRIEN/MAGAIDURU", "OLOLOSOKWAN", "ORGOSOROK", "PINYINYI", "PIYAYA", "SALE"],
    Ilala: ["GEREZANI", "ILALA", "JANGWANI", "KARIAKOO", "KISUTU", "KIVUKONI", "MCHAFUKOGE", "MCHIKICHINI", "UPANGA MAGHARIBI", "UPANGA ASHARIKI"],
    Segerea: ["BONYOKWA", "BUGURUNI", "KIMANGA", "KINYEREZI", "KIPAWA", "KISUKURU", "KIWALANI", "LIWITI", "MINAZI MIREFU", "MNYAMANI", "SEGEREA", "TABATA", "VINGUNGUTI"],
    Ukonga: ["BUYUNI", "CHANIKA", "GONGOLAMBOTO", "KIPUNGUNI", "KITUNDA", "KIVULE", "MAJOHE", "MSONGOLA", "MZINGA", "PUGU", "PUGU STATION", "UKONGA", "ZINGIZIWA"],
    Kigamboni: ["KIBADA", "KIGAMBONI", "KIMBIJI", "KISARAWE II", "MJIMWEMA", "PEMBA MNAZI", "SOMANGILA", "TUNGI", "VIJIBWENI"],
    Kawe: ["BUNJU", "KAWE", "KUNDUCHI", "MABWEPANDE", "MAKONGO", "MBEZI JUU", "MBENI", "MIKOCHENI", "MSASANI", "WAZO"],
    Kinondoni:["HANANASIF", "KIGOGO", "KIJITONYAMA", "KINONDONI", "MAGOMENI", "MAKUMBUSHO", "MWANANYAMALA", "MZIMUNI", "NDUGUMBI", "TANDALE"],
    Mbagala:["CHAMAZI", "CHARAMBE", "KIBONDEMAJI", "KIBURUGWA", "KIJICHI", "KILUNGULE", "MBAGALA", "MBAGALA KUU", "MIANZINI", "TOANGOMA"],
    Temeke: ["AZIMIO", "BUZA", "CHANG'OMBE", "KEKO", "KILAKALA", "KURASINI", "MAKANGARAWE", "MIBURANI", "MTONI", "SANDALI", "TANDIKA", "TEMEKE", " YOMBO VITUKA"],
    Kibamba:["GOBA", "KWEMBE", "KIBAMBA", "MBEZI", "MSIGANI", "SARANGA"],
    Ubungo:["KIMARA", "MABIBO", "MAKUBURI", "MAKURUMLA", "MANZESE", "MBURAHATI", "SINZA", "UBUNGO"],
    
  };
;

  const [selectedState, setSelectedState] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [Ward, setSelectedWard] = useState('');
  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [open3, setOpen3] = useState(false)  
  const [newfruit, setNewfruit] = useState("")

  
  const [isActive, setIsActive] = useState(false)     
    
    const turn = () =>{
        setIsActive(!isActive)
    }

  const handleToggle = () => {
    setOpen(!open);
      }

  const handleToggle2 = () => {
    setOpen2(!open2);
    
  }

  const handleToggle3 = () => {
    setOpen3(!open3);
    
  }


  useEffect(()=> {
    console.log("selected state",selectedState)
  }, [selectedState])



  const handleStateChange = (fruit) => {
    setSelectedState(fruit);
     setSmkoa(fruit)
    setSelectedDistrict('');
    setSelectedWard('');    
    setOpen2(false)
    const advert = document.getElementById("text2")
     advert.textContent = "Jimbo" 
     const advert3 = document.getElementById("text3")
    advert3.textContent = "Kata"

  };

const handleOPtionSelect = (fruit) => {
  
  console.log("option aquired",fruit)
  
  const advert = document.getElementById("text")
  
  advert.innerHTML = fruit
  handleStateChange(fruit)
  
  
  setOpen(false)
}

  const handleDistrictChange = (fruit) => {
    
    setSelectedDistrict(fruit);
    setSwilaya(fruit)
    setSelectedWard('');
    const advert2 = document.getElementById("text2")
    advert2.textContent = fruit
    const advert3 = document.getElementById("text3")
    advert3.textContent = "Kata"
    setOpen3(false)
  
  
  
  setOpen2(false)
  };

  const handleWardChange = (fruit) => {
    
    
    setSelectedWard(fruit);
    setSkata(fruit)
    const advert2 = document.getElementById("text3")
    

    advert2.textContent = fruit.toLocaleLowerCase()
           
      
  setOpen3(false)
  };

  
  
  return (
    
    <div className="searching" >
      
      <div className="select-menu">

        <div id='select-button' className="select-button" onClick={handleToggle}  >
            <span id='text' contentEditable="false" > Mkoa</span> <span className='down' > <div className={styles.down} ><Down className={open ? styles.ups : styles.down} fontSize="inherit"  /></div></span>
        </div>
        
          
            <div
            className="overflows" >
            <ul id='options' className="list"
            
            >
            
            {Tanzania.map((fruit, index)=> (
              <li 
              className={open ? "options2" : "options"}
              style={{"--i":  index , "--j": fruit.length - index}}
             key={fruit}
              onClick={() => handleOPtionSelect(fruit)}
              >
                {fruit}
              </li>
            ))}          
            </ul>
            </div>
        
        
    </div>

    <div className="select-menu">
 
<div id='select-button' className="select-button" onClick={handleToggle2}  >
    <span id='text2' contentEditable="false" > Jimbo</span> <span className='down' ><div className={styles.down} ><Down className={open2 ? styles.ups : styles.down} fontSize="inherit"  /></div></span>
</div>

      <div
    className="overflows">
    <ul id='options' className="list"
    >
    
    {selectedState && 
              districts[selectedState]?.map((fruit, index)=> (
      <li 
     
      className={open2 ? "options2" : "options"}
              style={{"--i":  index , "--j": fruit.length - index}}
     key={fruit}
      onClick={() => handleDistrictChange(fruit)}
      >
        {fruit}
      </li>
    ))}          
    </ul>
    </div>
  

   </div>
   <div className="select-menu">
 
<div id='select-button' className="select-button" onClick={handleToggle3}  >
    <span id='text3' contentEditable="false" > Kata</span> <span className='down' ><div className={styles.down} ><Down className={open3 ? styles.ups : styles.down} fontSize="inherit"  /></div></span>
</div>

 
    <div
    className="overflows">
    <ul id='options' className="list"
    >
    
    {selectedDistrict && 
              wards[selectedDistrict]?.map((fruit, index)=> (
      <li 
      
      className={open3 ? "options2" : "options"}
              style={{"--i":  index , "--j": fruit.length - index}}
     key={fruit}
      onClick={() => handleWardChange(fruit)}
      >
        {fruit}
      </li>
    ))}          
    </ul>
    </div>
  

   </div>
   
   <div className="round" onClick={trial} >
      <Searchz className={styles.search} />
    </div> 
      
          </div>
  );
};

export default CascadingOptions;
