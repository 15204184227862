import React from 'react'
import Head from '../components/Head'
import Tafuta from '../components/Tafuta'
import AliceCaru from '../components/AliceCaru'
import Footer from '../components/Footer'
import { useStateValue } from '../StateProvider'

function Search() {
    const [{mkoa, jimbo, kata}, dispatch] = useStateValue();
  return (
    <>
      <Head/>
      <Tafuta mkoa={mkoa} wilaya={jimbo} kata={kata} />
      <AliceCaru/>
      <Footer/>
    </>
  )
}

export default Search