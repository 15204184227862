import styles from "../styles/body.module.css";
import React,  { useReducer, useState, useEffect } from "react";
import { LocationOn, Hotel, Bathtub, LocalTaxi, Crop, AccessAlarm, CreditCard, ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { Rating } from "@mui/material";
import { Typography } from "@mui/material/styles/createTypography";
import useStyles from './icons'
import { Link, useNavigate } from "react-router-dom";
import Moment from "react-moment";
import { onSnapshot,  query, orderBy, collection, where, getDocs } from 'firebase/firestore'
import db from '../firebase'
import { useStateValue } from "../StateProvider";
import { actionTypes } from "../reducer";
import Loader from "../loader/Loader";



const slides = [
  {
      name: "ZERO",
      description: "Zero dalali kabisa",
      
  },
  {
      name: "ONE",
      description: "Nyumba ya KUpanga yenye Nafasi kubwa",
      
  },
  {
      name: "TWO",
      description:"Pata Nyumba bila kutoa mwezi mmoja kwa dalali ",
      
  },
  
  {
      name: "THREE",
      description: "Wananchi wana haki kupata makazi bila shuruti",
      
  },
  {
      name: "FOUR",
      description: "Wananchi wana haki kupata makazi bila shuruti",
      
  },
  {
      name: "FIVE",
      description: "ansd  there goes something must kill a man",
      
  }



]


const initialState = {

    slideIndex: 0
}
  

const slideReducer = (state, event) => {
     
    if(event.type === 'NEXT'){
       return {
           ...state,
           slideIndex: (state.slideIndex + 1) % slides.length
                        }
    }

    if(event.type === 'PREV'){
       return {
           ...state,
           slideIndex: state.slideIndex === 0
           ? slides.length - 1 
           : state.slideIndex - 1
       }
    }

}

 
 
 function Slide({slide, offset, key, id}){
    const classes = useStyles()
    const active = offset === 0 ? true : null ;
    const myref = React.useRef(null)
    const navigate = useNavigate()  
    const [cat, setCatz] = useState("Kupangisha")
    const [{user}, dispatch] = useStateValue(); 
    const [starz, setStarz] = useState(0)

    const send = () => {
      navigate(`/single/${id}`)
      addCatz()
     
    }
    const addCatz = () => {
      dispatch({
       type: actionTypes.SET_CATZ,
       payload: cat,
      })
    } 


    useEffect(()=> {

      const state = {
        rect: undefined,
        mouseX: undefined,
        mouseY: undefined,
    
     }
    
      const resizeObserver = new ResizeObserver(entries => {
        state.rect = myref.current.getBoundingClientRect()
     })
     const myco = myref.current
     const handleMouseMove2 = (event) => {
             
         if(!(myco && active )){ return }
          state.rect = myco.getBoundingClientRect()
           state.mouseX = event.clientX
           state.mouseY = event.clientY
           const px = (state.mouseX - state.rect.left) / state.rect.width
           const py = (state.mouseY - state.rect.top) / state.rect.height
           myco.style.setProperty('--px', px)
           myco.style.setProperty('--py', py)             
    }
    
    
    let newRef = myref.current
     newRef.addEventListener('mousemove', handleMouseMove2)
    resizeObserver.observe(newRef)
      console.log("listener added")
    
    
    return () => {
     resizeObserver.unobserve(newRef)
           newRef.removeEventListener('mousemove', handleMouseMove2)
           console.log("listener removed")
    }
    
    return myco
    
    }, [offset])
    
    const formatMoney = (kodis) => {
      if (kodis >= 1000000) {
        return (kodis / 1000000).toLocaleString(undefined, {minimumFractionDigits: kodis % 1000000 === 0 ? 0 : 1, maximumFractionDigits: kodis % 1000000 === 0 ? 0 : 1}) + "M";
      } else if (kodis >= 1000) {
        return (kodis / 1000).toLocaleString(undefined, {minimumFractionDigits: kodis % 1000 === 0 ? 0 : 1, maximumFractionDigits: kodis % 1000 === 0 ? 0 : 1}) + "k";
      }
      return kodis.toLocaleString();
    }   


   useEffect(() => {
    const starzs = () => {
      if(slide?.bafu){
           setStarz(1)
      }else if(slide?.bafu && slide?.jikoni){
        setStarz(2)
      }else if(slide?.bafu && slide?.jikoni && slide?.sebuleni){
        setStarz(3)
      }else if(slide?.bafu && slide?.jikoni && slide?.sebuleni && slide?.parking || slide?.feni){
        setStarz(4)
      }else if(slide?.bafu && slide?.jikoni && slide?.sebuleni && slide?.parking || slide?.ac || slide?.pool || slide?.wifi){
        setStarz(5)
      }
   }
   starzs()
    
   }, []) 

        
    
   return         <div className={styles.slidez}
   id="cody" ref={myref} data-active={active} key={key} 
   style={{
          '--offset' : offset,
          '--dir' : offset === 0 ? 0 : (offset > 0 ? 1 : -1),
          backgroundImage: `url(${slide?.image})`,
         }}
>
<div className={styles.conts} >
  
<div className={styles.lefts} >
<div className={styles.box} ><CreditCard  className={classes.icon} /> <h4 style={{fontWeight: "bolder"}} >Tsh {formatMoney(slide.kodi)}</h4></div>
<div className={styles.box} ><LocationOn className={classes.icon}/><h4>{slide.district}</h4></div>
<div className={styles.box} ><LocalTaxi className={classes.icon}/><h4>{slide.lami}</h4></div>
<div className={styles.box} ><Crop className={classes.icon}/><h4>{slide.sqft} Sqft</h4></div>
<div className={styles.box} ><AccessAlarm className={classes.icon}/><h4> <Moment fromNow>{slide?.timestamp?.toDate()}</Moment></h4></div>
  </div>
  <div className={styles.right} >
      <h2 className={styles.cheki} onClick={send} >Angalia</h2>
   <Rating
      className={classes.rate}
      name="read-oly"
      value={starz}
      readOnly
   />
  </div> 

</div>
</div> 
   }

function ParanomaD4() {
  const [state, dispatch] = React.useReducer(slideReducer, initialState)
    const [dar, setDar] = useState([])
    
    const postref = collection(db, "Kupangisha")
    const q1 = query(postref, where("property", "==", `Duka`), where("state", "==", `Mbeya`), orderBy("timestamp", "desc"))
    
    const [load, setLoad] = useState(true)
    useEffect(() => {
      
      const retrieve = async () => {
          try {
            const querySnap = await getDocs(q1)
            setDar(querySnap.docs)
          } catch (error) {
            console.error("Error fetching data", error);
          } finally {
             setLoad(false)   
          }                    
      }
      retrieve()
    }, [db])

    
            
  return (
    load ? <Loader/> 
      :
   
    <div className={styles.panam} >
        <div  className={styles.slideCont}>
        
           { [...dar, ...dar, ...dar]
              .map((slide, i) => {
               let offset = dar.length + (state.slideIndex - i);
               const active = offset === 0 ? true : null ;
               return  <div  className={styles.dark} 
               data-active={active} key={i}
                   style={{
                     '--offset' : offset,
                     '--dir' : offset === 0 ? 0 : (offset > 0 ? 1 : -1),
               backgroundImage: `url(${slide.data()?.image})`,
               }}></div>
           })}
                        
         </div>
         <div className={styles.realDark} >

         </div>

         <div className={styles.threeD}  id="scrollz"  >
         { [...dar, ...dar, ...dar]
              .map((slide, i) => {
               let offset = dar?.length + (state.slideIndex - i);
               const active = offset === 0 ? true : null ;
               return  <Slide slide={slide.data()} id={slide.id} offset={offset} key={i} />
           })}

          </div>  
          <div className={styles.upande}>
            <h2>Maduka ya Mbeya</h2>
          </div>

          <div className={styles.buttns} > 
             <div className={styles.butt} 
               onClick={() => {
                 dispatch({ type: 'PREV'})
               }}
             > 
                <ArrowBackIos className={styles.tabos} />
             </div>
             <div className={styles.butt}
                onClick={() => {
                    dispatch({ type: 'NEXT'})
                  }}
             >
                <ArrowForwardIos className={styles.tabos} />
            </div>
            
           </div>

    </div>  )
}

export default ParanomaD4