import React, { useEffect, useState } from 'react'
import styles from "../styles/try.module.css"
import { ArrowDropDown } from '@material-ui/icons'


function Trial() {
       
    const selectBtn = document.getElementById("select-buttom")
    const text = document.getElementById("text")
    const option = document.getElementById("option") 
    const [isActive, setIsActive] = useState(false)     
    
    const turn = () =>{
        setIsActive(!isActive)
    }


  

      return (
    <div className={styles.try} >
         <div className={styles.select_menu} >
            <div className={styles.select_button} id='select-buttom' onClick={turn} >
                <span id='text' > Drop down select </span>
                 <ArrowDropDown className={ isActive ? styles.active :  styles.arrow} />
            </div>

            <ul className={styles.list} >
                <li className={ isActive ? styles.option2 : styles.option} style={{"--i": 6}} id='option' >
                    <span className={styles.option_text} >Wakanda forever</span>
                </li>
                <li className={ isActive ? styles.option2 : styles.option} style={{"--i": 5}} >
                    <span className={styles.option_text} >the man of steel</span>
                </li>
                <li className={ isActive ? styles.option2 : styles.option} style={{"--i": 4}} >
                    <span className={styles.option_text} >the sandman</span>
                </li>
                <li className={ isActive ? styles.option2 : styles.option} style={{"--i": 3}} >
                    <span className={styles.option_text} >the queen of the south</span>
                </li>
                <li className={ isActive ? styles.option2 : styles.option} style={{"--i": 2}} >
                    <span className={styles.option_text} >avengers age of ultron</span>
                </li>
                <li className={ isActive ? styles.option2 : styles.option} style={{"--i": 1}} >
                    <span className={styles.option_text} >outlander scotland</span>
                </li>
            </ul>

         </div>
    </div>
  )
}

export default Trial