import React, { useState } from 'react'
import Carousel from 'react-material-ui-carousel'
import styles from "../styles/caru2.module.css";
import { Paper, Button, StylesProvider } from '@material-ui/core';



function Carusel2( {postz}, props)

{  
    
    var items = [
        {
            name: postz?.picha1,
            
            sosi: postz?.image,
        },
        {
            name: postz?.picha2,
            description: "Nyumba ya Kupanga yenye Nafasi kubwa",
            sosi: postz?.image2,
        },
        {
            name: postz?.picha3,
            description:"Pata Nyumba bila kutoa mwezi mmoja kwa dalali ",
            sosi: postz?.image3,
        },
        
        {
            name: postz?.picha4,
            description: "Wananchi wana haki kupata makazi bila shuruti",
            sosi: postz?.image4,
        },
        {
            name: postz?.picha5,
            description: "Wananchi wana haki kupata makazi bila shuruti",
            sosi: postz?.image5,
        }



    ]

    return (
        <Carousel>
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
     
            }
        </Carousel>

            
    )
}

function Item(props)
{
    return (
        <Paper className={styles.papers}>
               <div className={styles.ban}>
                <img  src={props.item.sosi} className={styles.imgz} />
                
                <h1 className={styles.name}>{props.item.name}</h1>
                 </div>
     
        </Paper>
    )
}

export default Carusel2