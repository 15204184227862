import React from 'react'
import Head from '../components/Head'
import About from './About'
import Footer from '../components/Footer'
import AliceCaru from '../components/AliceCaru'

function Aboutz() {
  return (
    <>
    <Head/>
    <About/>
    <AliceCaru/>
    <Footer/>

    </>
  )
}

export default Aboutz