import { getMessaging , onMessage, getToken} from "firebase/messaging";
import db from "./firebase";
import { setDoc, doc } from "firebase/firestore";
import messaging from "./firebase" 
import { CurrencyBitcoin } from "@mui/icons-material";

const message = getMessaging()
const key = "uxIpI13FkPkCMoHTxEiFg5NLs13AAXvLPF0BPGgI4TQ";
const FCM_TOKEN_COLLECTION = "users"

export async function requestNotification(uid){
     console.log("requesting permisisions")
     const permission = await Notification.requestPermission();

     if(permission === 'granted'){
        saveMessagingDeviceToken(uid)
     } else {
        console.log("unable to get permission master")
     }
}

export async function saveMessagingDeviceToken(uid){
     console.log("the function started")    
    const fcmToken = await getToken(message, { vapidKey : key})

    if(fcmToken){
        console.log("got fcm device token: ", fcmToken)
        const tokenRef = doc(db, FCM_TOKEN_COLLECTION, uid );
        await setDoc(tokenRef, {token : fcmToken})
        
        onMessage(message, (message) => {
            console.log("new foreground notification from firebase messaging",
            message.notification)
            new Notification(message.notification.title, { body: message.notification.body})
        })
    
    
    } else {
        requestNotification(uid)
    }
}

export const requestPermissionNow = () =>{
    console.log("requesting user permission")
    Notification.requestPermission().then((permission) => {
        if(permission === "granted"){
            console.log("Notificatiion granted fellaz")
            return getToken(message, {vapidKey: key})
            .then((currentToken) => {
                if(currentToken){
                    console.log("we have a token here", currentToken)
                }else {
                    console.log("we have failed ")
                }
            }).catch((err)=> {
                console.log("error here", err)
            })
        } else {
            console.log("user permission denied")
        }
    })   
}

