import React from 'react'
import styles from "../styles/advertise.module.css"


function About() {
  return (
    <div className={styles.adds} >
      <head>
      <meta name="description" content="Zero Dalali ni mtandao unaokutanisha mwenye nyumba na mpangaji bila ya dalali, Usikubali tena kutoa hela kwa dalali" />
    <link rel="icon" href="./dalali-icon.png"  />
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9205716940827637"
     crossorigin="anonymous"></script> 
    <title>Zero-Dalali</title>
      </head>
         <div className={styles.kings} > 
       <div className={styles.whites} ></div>
    
        <div className={styles.sample} >
             <h3>karibu Zero-Dalali</h3>
             <h5>Kwa kifupi hii ni taasisi ambayo iko na watu makini wenye malengo mazuri la kuona watanzania wanaishi kwenye maisha mazuri na bora kama ilivyo ndoto za kila mwananchi </h5>
      </div>
      
         <div className={styles.blacks}>
         </div>
      </div>
      <h4 className={styles.wordz} >
        Taasisi ya binafsi inayohusisha kuboresha huduma za upatikanaji wa nyumba za kupanga na kununua,
        tunatumia teknolojia ya mtandao kutimiza lengo la kuboresha maisha ya watanzania na kuwapunguzia kero nyingi sana ambazo zimekua zikiwakumba kutokana na madalali wa nyumba uko mtaani 

      </h4>

      <div className={styles.kings} > 
       <div className={styles.whites} ></div>
    
        <div className={styles.sample} >
             <h3>Lengo letu</h3>
             <h5>Lengo la Zero-Dalali ni moja tu nalo ni kuboresha maisha ya watanzania na kuondoa kero kubwa inayokumba watanzania na kusababisha huduma za makazi kua duni
                
                 </h5>
      </div>
      
         <div className={styles.blacks}>
         </div>
      </div>
       
       <h4 className={styles.wordz} >
        Lengo letu kuu ni kuboresha maisha ya watanzania kiujumla , hasa asa watanzania wa hali ya chini wanaosumbuka kutafuta nyumba kutokana na manyanyaso na uonezi wanayopata toka kwa dalali huko mtaani,
        hili limekua ni jambo moja zito sana na nyeti sana ambalo kwa muda mrefu sana limekuwepo likiendelea bila kuchukuliwa hatua zozote zile, Watanzania wanastahili makazi bora na na wanastahili haki ya kuchagua makazi bora bila kushurutishwa na mtu yoyote yule
       </h4>
       
       <div className={styles.kings} > 
       <div className={styles.whites} ></div>
    
        <div className={styles.sample} >
             <h3>Maono yetu</h3>
             <h5>
                Maono yetu ni kuona Tanzania inakua na kuendelea katika swala la uboreshwaji wa makazi bora kwa watanzania wote
                 </h5>
      </div>
      
         <div className={styles.blacks}>
         </div>
      </div>
       <h4 className={styles.wordz} >
          Hii ni moja ya maono yetu makubwa, watanzania wapate huduma bora za makazi , waishi sehemu safi na salama, waraisishiwe kutafuta makazi ya kuishi , wasisumbuliwe wala kushurutishwa au unyanyasaji wa aina yoyote ile
          
       </h4>
    </div>
  )
}

export default About