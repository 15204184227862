import React from 'react'
import styles from "../styles/duka.module.css"
import ParanomaO2 from './ParanomaO2'
import ParanomaO3 from './ParanomaO3'
import ParanomaO4 from './ParanomaO4'
import ParanomaO5 from './ParanomaO5'
import ParanomaO1 from './ParanomaO1'

function Ofisi() {
  return (
    <div className={styles.duka} >
          <ParanomaO1/>
          <ParanomaO2/>
          <ParanomaO3/>
          <ParanomaO4/>
          <ParanomaO5/>
    </div>
  )
}

export default Ofisi