import React, { useState, useEffect} from 'react'
import styles from "../styles/body.module.css"
import Paranoma from './Paranoma'
import Paranoma2 from './Paranoma2'
import Paranoma3 from './Paranoma3'
import Paranoma4 from './Paranoma4'
import Paranoma5 from './Paranoma5'
import { VpnKey, LocationOn, Hotel, Bathtub,LocalParking, EventSeat, LocalTaxi, Crop, AccessAlarm, CreditCard, Kitchen, Pool, Wifi } from '@material-ui/icons'
import { Rating } from '@mui/material'
import Maps from './Maps'
import Duka from './Duka'
import Ofisi from './Ofisi'
import Godown from './Godown'
import useStyles from "./icons"
import { onSnapshot,  query, orderBy, collection, where, getDocs } from 'firebase/firestore'
import db from '../firebase'
import RoofingIcon from "@mui/icons-material/Roofing"
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag"
import Apartment from "@mui/icons-material/Apartment"
import Warehouse from "@mui/icons-material/Warehouse"
import CropLand from "@mui/icons-material/CropLandscape"



function Body() {
  const [dar, setDar] = useState([])
  const classes = useStyles()
  const [select, setSelect] = useState(0)
  const [ open, setOpen] = useState(false)
  const [count, setCount] = useState(0)
  const divs = [
      { id: 0, label: "NYUMBA", link: "/pag1"},
      { id: 1, label: "DUKA", link: "/pag2"},
      { id: 2, label: "OFISI", link: "/pag3"},
      { id: 3, label: "GO-DOWN", link: "/pag4"},
      
  ]
  const divClicked = (index) => {
        setSelect(index)
  }

  const HandleOpen = () => {
    setOpen(!open)
  }
  
    
  
  
           

  return (
    <div className={styles.bodys} >
          <div className={styles.pango} >
            <div className={styles.jumla} >         
             {divs.map((div) => (
              <div className={styles.cover}  key={div.id}
              onClick={() => divClicked(div.id)}
               >
               {div.id === 0 && (<div className={styles.topz} style={{color: select === div.id ? "red" : "white"}} ><RoofingIcon fontSize='inherit' /></div>) } 
               {div.id === 1 && (<div className={styles.topz} style={{color: select === div.id ? "red" : "white"}} ><ShoppingBagIcon fontSize='inherit' /></div>) }
               {div.id === 2 && (<div className={styles.topz} style={{color: select === div.id ? "red" : "white"}} ><Apartment fontSize='inherit' /></div>) }
               {div.id === 3 && (<div className={styles.topz} style={{color: select === div.id ? "red" : "white"}} ><Warehouse fontSize='inherit' /></div>) }
              <h2 className={styles.navy2} 
                  style={{
                    borderBottom: select === div.id ? "0.5vh solid red" : "none",
                   
                 }}
              >
                {div.label}
              </h2>
              </div>
         ))}
       
         </div>

         
         <VpnKey className={styles.icona} onClick={HandleOpen} />
        <div className={open ?  styles.keys : styles.keys2} >
          
          <div className={open ?   styles.meaning : styles.meaningz} >
               <div className={styles.means} ><CreditCard className={classes.icon} /><h4 className={styles.con2} >Kodi kwa mwezi</h4></div>
               <div className={styles.means} ><Hotel className={classes.icon} /><h4 className={styles.con2} >vyumba vya kulala</h4></div>
               <div className={styles.means} ><Bathtub className={classes.icon} /><h4 className={styles.con2} >Choo na bafu ndani ya nyumba</h4></div>
               <div className={styles.means} ><Crop className={classes.icon} /> <h4 className={styles.con2} >Futi za mraba</h4> </div> 
               <div className={styles.means} ><LocalTaxi className={classes.icon} /><h4 className={styles.con2} >Umbali kutoka lami</h4></div>
               <div className={styles.means} ><LocationOn className={classes.icon} /><h4 className={styles.con2} >Mahali ilipo</h4></div>
               <div className={styles.means} ><EventSeat className={classes.icon} /><h4 className={styles.con2} >Sebule</h4></div>
               <div className={styles.means} ><Kitchen className={classes.icon} /><h4 className={styles.con2} >Jikoni</h4></div>
               <div className={styles.means} ><Pool className={classes.icon} /><h4 className={styles.con2} >Bwawa la kuogelea</h4></div>
               <div className={styles.means} ><Wifi className={classes.icon} /><h4 className={styles.con2} >Wifi au AC</h4></div>
               <div className={styles.means} ><AccessAlarm className={classes.icon} /><h4 className={styles.con2} >Muda tokea ipostiwe</h4></div>
               <div className={styles.means} ><LocalParking className={classes.icon} /><h4 className={styles.con2} >Parking ya gari</h4></div>
               
          </div>

          <div className={open ?  styles.meaning2 : styles.meaning2Z} >
              <div className={styles.rates} > 
              <Rating
                  className={classes.rate2}
                  name="read-oly"
                  value={1}
                  readOnly
              />  
                    <div className={styles.Rt} >
                <Hotel className={classes.icon} />
                <Bathtub className={classes.icon} />
                    </div>
            <div/>
        
            </div>

              <div className={styles.rates} >
              <Rating
                  className={classes.rate2}
                  name="read-oly"
                  value={2}
                  readOnly
              />   <div className={styles.Rt} >
                <Hotel className={classes.icon} />
                <Bathtub className={classes.icon} />
                <Kitchen className={classes.icon} />
                </div>
              </div>

              <div className={styles.rates} >
              <Rating
                  className={classes.rate2}
                  name="read-oly"
                  value={3}
                  readOnly
              />   <div className={styles.Rt} >
                <Hotel className={classes.icon} />
                <Bathtub className={classes.icon} />
                <Kitchen className={classes.icon} />
                <EventSeat className={classes.icon} /></div>
              </div>
              <div className={styles.rates} >
              <Rating
                  className={classes.rate2}
                  name="read-oly"
                  value={4}
                  readOnly
              />   <div className={styles.Rt} >
                <Hotel className={classes.icon} />
                <Bathtub className={classes.icon} />
                <Kitchen className={classes.icon} />
                <EventSeat className={classes.icon} />
                <LocalParking className={classes.icon} /></div>
              </div>
              <div className={styles.rates} >
              <Rating
                  className={classes.rate2}
                  name="read-oly"
                  value={5}
                  readOnly
              />   <div className={styles.Rt} >
                <Hotel className={classes.icon} />
                <Bathtub className={classes.icon} />
                <Kitchen className={classes.icon} />
                <EventSeat className={classes.icon} />
                <LocalParking className={classes.icon} />
                <Wifi className={classes.icon} />
                <Pool className={classes.icon} /></div>
              </div>
          </div>
        </div>
          </div>
          
          {select === 0 && <> 
              <Paranoma/>
              <Paranoma2/>
              <Paranoma3/>
              <Paranoma4/>
              <Paranoma5/>
           </>}
          {select === 1 && <Duka/>}
          {select === 2 && <Ofisi/> }
          {select === 3 && <Godown/> }
          <div className={styles.neighbour} >
              <div className={styles.use} > 
                   <h3>Pata taarifa kuhusu mtaa unaotaka kuamia</h3>
                   <h5>Tumia ramani kuangalia vitu vya msingi kuhusu mtaa unaotaka kuhamia kama vile shule zilizopo karibu, vituo vya afya , barabara, hoteli n.k</h5>
              </div>
              <Maps/>
          </div>
    </div>
  )
}

export default Body