import React, { useState, useEffect } from 'react'
import styles from "../styles/advertise.module.css"
import { WhatsApp } from "@material-ui/icons"
import useStyles from './icons'
import { Link } from 'react-router-dom'

function Advertise() {
  const [show2, handleShow2] = useState(false)
  const classes = useStyles() 


  useEffect(() => {
        const handlescroll = () =>{
          if (window.scrollY > 50) {
            handleShow2(true)
        } else {handleShow2(false)
    }    
        }
        window.addEventListener("scroll", handlescroll);
        return () => {
          window.removeEventListener("scroll", handlescroll);
      }
           
  }, [])


  return (
    <div className={styles.adds} >
     <div className={styles.kings} > 
       <div className={styles.whites} ></div>
    
        <div className={styles.sample} >
             <h3>karibu utangaze nasi</h3>
             <h5>tunapokea matangazo ya aina yote, tukusaidie kufikisha lengo lako la kibiashara kwa kukufikishia matangazo yako kwa hadhira husika</h5>
      </div>
      
         <div className={styles.blacks}>
         </div>
      </div>
      <h4 className={styles.wordz} >
        Tunakupa fursa ya pekee na ya aina yake ya kugusa moja kwa moja walengwa wako kwa namna ya tofauti kabisa,
        utakua na uwezo wa kurusha matangazo yako kwa njia ya picha na video pia kwa gharama nafuu sana,
        karibu Zero-Dalali tunakupa nafasi ya pekee ya kufikisha biasharako kwa watu ambao wako tayari na huduma yako 

      </h4>

      <div className={styles.kings} > 
       <div className={styles.whites} ></div>
    
        <div className={styles.sample} >
             <h3>Unganishwa na Dunia</h3>
             <h5>Tunakupa fursa ya pekee ya kuunganishwa na Tanzania nzima kwa ujumla, kila kona kila pande za nchi ,
              matangazo yako yataweza kuwafikia watu wote kwa urahisi zaidi na kwa gharama nafuu
             </h5>
      </div>
      
         <div className={styles.blacks}>
         </div>
      </div>
      <h4 className={styles.wordz} >
        Unganishwa na nzhi nzima , panua uwanja wa biashara yako kwa kuwafikia watu ambao hujawahi wafikia kabla ,
        kuza biashara yako kwa namna ambayo haijawahi tokea hapo mwanzo , tashmini matokeo ya biashara yako baada ya kutangaza nasi nawe utaona utofauti ambao haujawahi ona apo kabla,
        Zero-Dalali tunakupa kitu cha tofauti ambacho hujawahi kukipata kwenye biashara , utagundua nguvu ya matangazo 
      </h4>
      <div className={styles.kings} > 
       <div className={styles.whites} ></div>
    
        <div className={styles.sample} >
             <h3>Chat nasi </h3>
             <h5>Bonyeza kitufe chat watsapp kilichopo pembeni yako kuchat nasisi moja kwa moja ,
               Kukuza biashara yako ni lengo letu sote , tena kwa bei nafuu kabisa,
               wasiliana nasisi moja kwa moja kupitia WhatsApp tuyajenge
             </h5>
      </div>
      
         <div className={styles.blacks}>
         </div>
      </div>
      <h4 className={styles.wordz} >
        Karibu inbox kwetu tuyajenge, tunakupa nafasi ya kujadili nasisi moja kwa moja na kuona ni njia gani bora kufikisha matangazo yako kwa jamii yetu,
        karibu wahi mapema , utajibiwa hapo hapo maswali yako yote  
      </h4>
      <div className={show2 ? styles.wasop : styles.wasap} >
      <Link to="https://wa.me/255743196242?text=Karibu%20zero%20Dalali%20tuyajenge" target='blank' rel='noopener norefferer' className={styles.lin} >
       <WhatsApp className={classes.watsap} />   
      </Link>
      
      </div>
    </div>
  )
}

export default Advertise