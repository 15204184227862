import React from 'react'
import styles from "../styles/App.css"
import Body from '../components/Body'
import Head from '../components/Head'
import Billboard from '../components/Billboard'
import Footer from '../components/Footer'
import House from '../components/House'
import AliceCaru from '../components/AliceCaru'



function Home() {
  return (
    <>
        <Head/>
        <Billboard/>
        <AliceCaru/>
        <Footer/>   
    </>
  )
}

export default Home