export const initialState = {
    user: null,
    catz: "",
    mkoa: "",
    jimbo: "",
    kata: "",
};

export const actionTypes = {
    SET_USER: "SET_USER",
    SET_CATZ: "SET_CATZ",
    SET_MKOA: "SET_MKOA",
    SET_JIMBO: "SET_JIMBO",
    SET_KATA: "SET_KATA",
};

const reducer = (state, action) => {
    console.log(action);
    switch (action.type) {
        case actionTypes.SET_USER:
            return {
                ...state,
                user: action.user,
            }
        case actionTypes.SET_CATZ:
                return {
                    ...state,
                    catz: action.payload,
                };
        case actionTypes.SET_MKOA:
                    return {
                        ...state,
                        mkoa: action.payload,
                    };    
                    case actionTypes.SET_JIMBO:
                        return {
                            ...state,
                            jimbo: action.payload,
                        }; 
                        case actionTypes.SET_KATA:
                            return {
                                ...state,
                                kata: action.payload,
                            };                        


            default:
                return state;
    }
};

export default reducer