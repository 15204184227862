import React, { useEffect, useState } from 'react'
import styles from "../styles/maps.module.css"
import GoogleMapReact from 'google-map-react';
import { InfoWindow } from '@react-google-maps/api';


function Maps() {

  const coordinates = {
    lat : -5,
    lng : 34,
  }

     
     
     useEffect(() => {
          navigator.geolocation.getCurrentPosition( position => {
          console.log("position" , position)
         }, error => {
          console.log('error', error.message)
         })
         
     }, [])
    
  return (
    <div className={styles.maps} >
      <GoogleMapReact
      bootstrapURLKeys={{ "key": "AIzaSyCYeVFMDqfpme7SSViL8EEGRIRKyUVWAKU"}}
      defaultCenter={coordinates}
      defaultZoom={6}
      margin={[50, 50, 50, 50]}
      onChange={(e) => {
        console.log("the revelations",e)
      }}
      options={''}
      onChildClick={''}
      className={styles.google}
      >

      </GoogleMapReact>
    </div>
  )
}

export default Maps