import React, { useState, useEffect } from 'react';
import styles from "../styles/house.module.css";
import Carusel2 from './Carusel2';
import { VpnKey, LocationOn, Hotel, Bathtub,LocalParking, EventSeat, LocalTaxi, Crop,
     AccessAlarm,Ballot, CreditCard, Kitchen, Pool, Wifi, CropSquare, TagFaces, Phone, SquareFoot, LocalPlayOutlined, LocalDining, LocalConvenienceStoreTwoTone, FlashAuto, Home } from '@material-ui/icons';
import useStyles from "./icons"
import { onSnapshot,  query, doc, getDoc, orderBy, collection, where } from 'firebase/firestore'
import db from '../firebase'
import Moment from 'react-moment';
import { useStateValue } from '../StateProvider';
import Loader from '../loader/Loader';


function House({id}) {
   const [{catz}, dispatch] = useStateValue()
    const [post, setPost] = useState([])
    const kodis = post.kodi
    const [load, setLoad] = useState(true)  

 
 

useEffect(() => {
   
     
      const fetchPostData = async () => {
          try {
            const docRef = doc(db, `${catz}`, id);
            const snapshot = await getDoc(docRef);
            if (snapshot.exists()) {
              setPost(snapshot.data());
            }      
          } catch (error) {
            console.error("Error fetching data", error);
          } finally {
            setLoad(false)
          }                    
      }
   
   
 
   fetchPostData();
 
   return () => {
     // Cleanup function to unsubscribe or handle any other necessary cleanup
   };
 }, [db, id, catz]);
 
 



  const formatMoney = (kodis) => {
   if(kodis === undefined) return
    else{
      if (kodis >= 1000000) {
         return (kodis / 1000000).toLocaleString(undefined, {minimumFractionDigits: kodis % 1000000 === 0 ? 0 : 1, maximumFractionDigits: kodis % 1000000 === 0 ? 0 : 1}) + "M";
       } else if (kodis >= 1000) {
         return (kodis / 1000).toLocaleString(undefined, {minimumFractionDigits: kodis % 1000 === 0 ? 0 : 1, maximumFractionDigits: kodis % 1000 === 0 ? 0 : 1}) + "k";
       }
       return kodis.toLocaleString();
    }
   
 }
  
 
 


   const classes = useStyles()
    const squares = "per sqm"
  return (
     
   load ? <Loader/> :

        <div className={styles.house} >
         <head>
              <title>Karibu Zero dalali </title>
              <meta name="description" content="Generated by react" />
              <link rel="icon" href="/favicon.ico" />
            </head>
            <div className={styles.lefta} >
                <div className={styles.disc} >
                   <h2>Nyumba bora kwa wakati sahihi</h2>
                   <h4>Jipatie nyumba bora sana bila gharama za dalali, unganishwa moja kwa moja na mwenye nyumba kwa haraka zaid</h4>
                </div>
                <div className={styles.disc} >
                   <h2>Usikubali kupigwa na madalali</h2>
                   <h4>Jipatie nyumba bora sana bila gharama za dalali, unganishwa moja kwa moja na mwenye nyumba kwa haraka zaid</h4>
                </div>
                <div className={styles.disc} >
                   <h2>makazi bora ni haki ya kilamtu</h2>
                   <h4>Jipatie nyumba bora sana bila gharama za dalali, unganishwa moja kwa moja na mwenye nyumba kwa haraka zaid</h4>
                </div>
                <div className={styles.disc} >
                   <h2>Jipatie viwanja bora</h2>
                   <h4>Jipatie viwanja bora sana bila gharama za dalali, unganishwa moja kwa moja na mwenye nyumba kwa haraka zaid</h4>
                </div>
                <div className={styles.disc}>
                   <h2>Unatafuta ofisi</h2>
                   <h4>Jipatie ofisi bora sana bila gharama za dalali, unganishwa moja kwa moja na mwenye nyumba kwa haraka zaid</h4>
                </div>
                
                <div className={styles.neighbourz}></div>
            </div>

            <div className={styles.mido} >
                 
                                              
                                             <Carusel2 postz={post} />
                                             <div className={styles.portions} >
                                                <AccessAlarm className={classes.icon3} /><h2 className={styles.loc} ><Moment className={styles.mount} fromNow>{post?.timestamp?.toDate()}</Moment></h2>
                                             </div> 
                                             <div className={styles.wote} >
                                             <div className={styles.portions} >
                                                <LocationOn className={classes.icon3} /><div className={styles.locals} >
                                                <h2 className={styles.loc} >{post?.state}</h2>
                                                <h2 className={styles.loc} >{post?.district}</h2>
                                                <h2 className={styles.loc} >{post?.ward}</h2>
                                                <h2 className={styles.loc} >{post?.mtaa}</h2>
                                                </div>
                                             </div>
                                             <div className={styles.portions} >
                                                <CreditCard className={classes.icon3} /><h2 className={styles.loc} >Tsh {formatMoney(post.kodi)} {post.property === "Kiwanja" && <span>Per sqm</span> } </h2>
                                             </div>
                                             {post?.room && <div className={styles.portions} >
                                                <Hotel className={classes.icon3} /><h2 className={styles.loc} >{post?.room}<span> (vyumba vya kulala)</span></h2>
                                             </div>}
                                             
                                                {post?.bafu  &&  <div className={styles.portions} >
                                                <Bathtub className={classes.icon3} /><h2 className={styles.loc} >bafu ndani lipo</h2>
                                             </div>}
                                               {post?.sebule &&  <div className={styles.portions} >
                                                <EventSeat className={classes.icon3} /><h2 className={styles.loc} >sebule ipo</h2>
                                             </div>}
                                              {post?.jikoni && <div className={styles.portions} >
                                                <Kitchen className={classes.icon3} /><h2 className={styles.loc} >jiko ndani lipo</h2>
                                             </div>}
                                             {post?.sqft && <div className={styles.portions} >
                                                <SquareFoot className={classes.icon3} /><h2 className={styles.loc} >{post?.sqft} sqft </h2>
                                             </div>}
                                             {post?.large && <div className={styles.portions} >
                                                <CropSquare className={classes.icon3} /><h2 className={styles.loc} > {post?.large} mita za mraba(sqm)</h2>
                                             </div>}
                                             {post?.miezilipa && <div className={styles.portions} >
                                                <Ballot className={classes.icon3} /><h2 className={styles.loc} >Awamu ni {post?.miezilipa}</h2>
                                             </div>}
                                               {post?.gym && <div className={styles.portions} >
                                                <LocalPlayOutlined className={classes.icon3} /><h2 className={styles.loc} >Gym ipo</h2>
                                             </div>}
                                             {post?.dine && <div className={styles.portions} >
                                                <LocalDining className={classes.icon3} /><h2 className={styles.loc} >Dinning room ipo</h2>
                                             </div>}
                                          
                                             
                                              {post?.parking && <div className={styles.portions} >
                                              <LocalParking className={classes.icon3} /><h2 className={styles.loc} >Parking ipo</h2>
                                             </div>}
                                             {post?.tiles && <div className={styles.portions} >
                                              <FlashAuto className={classes.icon3} /><h2 className={styles.loc} >Tiles & gypsum zipo</h2>
                                             </div>}
                                
                                
                                           {post?.balcony && <div className={styles.portions} >
                                              <LocalConvenienceStoreTwoTone className={classes.icon3} /><h2 className={styles.loc} >Balcony ipo</h2>
                                           </div>}
                                
                                             {post?.pool && <div className={styles.portions} >
                                                <Pool className={classes.icon3} /><h2 className={styles.loc} >swimming pool ipo</h2>
                                             </div>}
                                             {post?.wifi && <div className={styles.portions} >
                                                <Wifi className={classes.icon3} /><h2 className={styles.loc} >Wifi ipo</h2>
                                             </div>}
                                              {post?.mwakaIlojengwa && <div className={styles.portions} >
                                                <Home className={classes.icon3} /><h2 className={styles.loc} >Imejengwa mwaka {post?.mwakaIlojengwa}</h2>
                                             </div>}
                                             <div className={styles.portions} >
                                                <LocalTaxi className={classes.icon3} /><h2 className={styles.loc} >{post?.lami}</h2>
                                             </div>
                                             </div>
                                
                                
                                
                                             {post.property === "Kiwanja" ? <h4 className={styles.simu} >Wasiliana na mwenye Kiwanja</h4> : <h4 className={styles.simu} >Wasiliana na mwenye nyumba</h4> }
                                             <div className={styles.nyumbas} >
                                               <img src={post?.userImg} alt={post?.username} className={styles.user} /><h2>{post?.username}</h2>
                                             </div>
                                             <div className={styles.collar} >
                                             <div className={styles.portions} >
                                                <Phone className={classes.icon3} /><h2 className={styles.loc} >{post?.simu}</h2>
                                             </div>
                                             </div>
                                             
                                
                          </div>





             <div className={styles.raita} >
            <div className={styles.disc} >
                   <h2>Nyumba bora kwa wakati sahihi</h2>
                   <h4>Jipatie nyumba bora sana bila gharama za dalali, unganishwa moja kwa moja na mwenye nyumba kwa haraka zaid</h4>
                </div>
                <div className={styles.disc} >
                   <h2>Usikubali kupigwa na madalali</h2>
                   <h4>Jipatie nyumba bora sana bila gharama za dalali, unganishwa moja kwa moja na mwenye nyumba kwa haraka zaid</h4>
                </div>
                <div className={styles.disc} >
                   <h2>makazi bora ni haki ya kilamtu</h2>
                   <h4>Jipatie nyumba bora sana bila gharama za dalali, unganishwa moja kwa moja na mwenye nyumba kwa haraka zaid</h4>
                </div>
                <div className={styles.disc} >
                   <h2>Jipatie viwanja bora</h2>
                   <h4>Jipatie viwanja bora sana bila gharama za dalali, unganishwa moja kwa moja na mwenye nyumba kwa haraka zaid</h4>
                </div>
                <div className={styles.disc}>
                   <h2>Unatafuta ofisi</h2>
                   <h4>Jipatie ofisi bora sana bila gharama za dalali, unganishwa moja kwa moja na mwenye nyumba kwa haraka zaid</h4>
                </div>
              
             </div> 

    </div>
  )
}

export default House