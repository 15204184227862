import "./load.css"

const Loader = () => {



    return (
        <div className="loader" >  
           <div class="center">
  <div className="wave"></div>
  <div className="wave"></div>
  <div className="wave"></div>
  <div className="wave"></div>
  <div className="wave"></div>
  <div className="wave"></div>
  <div className="wave"></div>
  <div className="wave"></div>
  <div className="wave"></div>
  <div className="wave"></div>
</div>
        </div>
    )
}

export default Loader