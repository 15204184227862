import React, { useState, useEffect } from 'react'
import styles from "../styles/profile.module.css"
import { Close, AccessAlarm, Cancel, CreditCard, CropSharp, Delete, Edit, EditOutlined } from '@material-ui/icons'
import useStyles from './icons'
import { useStateValue } from '../StateProvider'
import Moment from "react-moment";
import { onSnapshot,deleteDoc,doc, setDoc, getCountFromServer,  query, orderBy, collection, where, getDocs, setIndexConfiguration } from 'firebase/firestore'
import db from '../firebase'
import { deleteObject } from 'firebase/storage'
import { Modal } from '@material-ui/core'



function Profile( {open, setOpen, setTotals}) {
  const [fungua, setFungua] = useState(false)
  const [fungua2, setFungua2] = useState(false)
  const [postId, setPostId] = useState("")
  const [kodiz, setKodiz] = useState(0) 
  const [{user}] = useStateValue()
  const [come, setCome] = useState(false)
  const [bring, setBring] = useState(false)
  const [inde, setInde] = useState("")  
  const classes = useStyles()
    const handleOpen = () => {
        setOpen(!open)
     }


     const [darz, setDarz] = useState([])
     const [darz2, setDarz2] = useState([])
     const [length, setLength] = useState(0)
     const [width, setWidth] = useState(0) 
    
    const deleto = (ids) => {
      setPostId(ids)
      setCome(true)  
      
    }
    const Edito = (index) => {
       setInde(index)  
    } 

      const postrefz = collection(db, "Kupangisha")
      const postrefz2 = collection(db, "Kununua")
      const qz1 = query(postrefz, where("id", "==", `${user?.uid}`), orderBy("timestamp", "desc"))
      const qz2 = query(postrefz2, where("id", "==", `${user?.uid}`), orderBy("timestamp", "desc"))
      


      useEffect(() => {
        const retrievez = async () => {    
          const querySnapz = await getDocs(qz1)
          const counterz = await getCountFromServer(qz1)
          setWidth(counterz.data().count)
           const newDocs = (querySnapz.docs) 
           if(newDocs !== darz){
            setDarz(newDocs)
            console.log("goods received", darz)
           }           
       } 
       const retrievez2 = async () => {    
        const querySnapz2 = await getDocs(qz2)
         const newDocs2 = (querySnapz2.docs)
         const counterz2 = await getCountFromServer(qz2)
          setLength(counterz2.data().count) 
         if(newDocs2 !== darz2){
          setDarz2(newDocs2)
          console.log("goods received", darz2)
         }           
     }       
       retrievez()
       retrievez2()
       setTotals(length + width)
  
      }, [])

      
      const sendKodi = async (id) => {
        const docRefx = doc(db, 'Kupangisha', id);
        await setDoc(docRefx, {
          kodi: kodiz,
        });
        setInde(undefined);
        setFungua2(true);
      }
      const sendKodi2 = async (id) => {
        const docRefx2 = doc(db, 'Kununua', id);
        await setDoc(docRefx2, {
          kodi: kodiz,
        });
        setInde(undefined);
        setFungua2(true);
      }
    
      
      const formatMoney = (kodis) => {
        if (kodis >= 1000000) {
          return (kodis / 1000000).toLocaleString(undefined, {minimumFractionDigits: kodis % 1000000 === 0 ? 0 : 1, maximumFractionDigits: kodis % 1000000 === 0 ? 0 : 1}) + "M";
        } else if (kodis >= 1000) {
          return (kodis / 1000).toLocaleString(undefined, {minimumFractionDigits: kodis % 1000 === 0 ? 0 : 1, maximumFractionDigits: kodis % 1000 === 0 ? 0 : 1}) + "k";
        }
        return kodis.toLocaleString();
      }   
  
    
    

  return (
    <div className={open ? styles.prof : styles.prof2}  >
   <Modal
  open={fungua}
  onClose={(e) => {
    e.stopPropagation();
    setFungua(false);
  }}
  >
  <div className={styles.modo}  onClick={(e)=> {
    e.stopPropagation();
  }}>
     <h2 className={styles.karibuz}> Mali imeondolewa Sokoni </h2>
     <h4 className={styles.binyaz} >Baada ya dakika kumi Itakua haipo</h4>
     <button className={styles.ingia} onClick={(e)=> {
                                    e.stopPropagation();
                                    setFungua(false);
                                    
                                }} >Sawa</button>
  </div>
</Modal>
<Modal
  open={fungua2}
  onClose={(e) => {
    e.stopPropagation();
    setFungua2(false);
  }}
  >
  <div className={styles.modo}  onClick={(e)=> {
    e.stopPropagation();
  }}>
     <h2 className={styles.karibuz}> Mali imefanikiwa kubadilishwa </h2>
     <h4 className={styles.binyaz} >Baada ya dakika kumi Itabadilika</h4>
     <button className={styles.ingia} onClick={(e)=> {
                                    e.stopPropagation();
                                    setFungua2(false);
                                    
                                }} >Sawa</button>
  </div>
</Modal>


        <div  className={styles.tops} >
        <Cancel className={classes.iconp} onClick={handleOpen} />
        <h2 className={styles.cus} >
         Karibu kwenye Zero Dalali
        </h2> 
        
        </div>
        <div className={styles.leads} >
          <img src={user?.photoURL} alt={user?.username} className={styles.imagex} />
          <h4>{user?.displayName}</h4>
        </div>
       
       <h3>Angalia mali zako</h3>
       {darz2.map((dar, index) => (
          <div className={styles.dem} key={index} >
          <div className={styles.clicks} >
            <img className={styles.imo} src={dar.data()?.image} alt='' />
            
            <div className={styles.downs}>
               <div className={styles.left}><CreditCard className={classes.iconp}/>
                <h4 className={styles.verbs}>{formatMoney(dar.data()?.kodi)}</h4>
               </div>
               
               <div className={styles.right} >
                <div className={styles.bold} onClick={() => deleto(dar.id)} > <Delete className={classes.delete}  /><span>Delete</span> </div>
                <div className={styles.bold} onClick={() => Edito(dar.id)} > <Edit className={classes.edits}  /><span>Edit</span></div>
                </div>
               <div className={styles.next} ><AccessAlarm className={classes.iconp}/>
               <h4 className={styles.verbs} > <Moment fromNow>{dar.data()?.timestamp?.toDate()}</Moment></h4></div>
            </div>
          </div>
              
                   <div  className={come ? styles.paperz : styles.paperz2} >
       <div className={styles.sure}>
          <h4>Yooo slow down!!</h4>
           <h3>Are u sure u wanna Delete?</h3>
           <div className={styles.buttns}>
              <button className={styles.nope} onClick={() => {
                setCome(false);
              }} >No</button>
              <button className={styles.yap} onClick={() =>  {
                   deleteDoc(doc(db, "Kupangisha", postId))
                   .then(() => {
                    console.log("document deleted succesfully")
                   })
                   .catch((error) => {
                    console.log("error deleting document", error)
                   })
                   setCome(false)
                   setFungua(true)
              }} >Yes</button>
           
           </div>
       </div>
                  </div>
                  <div className={ dar.id === inde ? styles.anonymous : styles.anonymous2} >
                    <div className={styles.clos} onClick={() => setInde("")} ><Close className={classes.iconp} /></div>
             <h4>Badilisha Kiasi cha kodi</h4>
                          
                <input className={styles.inputz} type='number' value={kodiz} onChange={(e) => setKodiz(e.target.value)} placeholder={dar.data()?.kodi} />
                <button className={styles.badilishaz} onClick={() => sendKodi(dar.id)} >badilisha</button>
          </div>

          </div>
          
       ))}

        {darz.map((dar, index) => (
          <div className={styles.dem} key={index} >
          <div className={styles.clicks} >
            <img className={styles.imo} src={dar.data()?.image} alt='' />
            <div className={styles.downs}>
               <div className={styles.left}><CreditCard className={classes.iconp}/> <h4 className={styles.verbs}>{formatMoney(dar.data()?.kodi)}</h4></div>
               
               <div className={styles.right} ><Delete className={classes.delete} onClick={() => deleto(dar.id)} />
               <Edit className={classes.icon} onClick={() => Edito(dar.id)} /> </div>
               <div className={styles.next} ><AccessAlarm className={classes.iconp}/><h4 className={styles.verbs} > <Moment fromNow>{dar.data()?.timestamp?.toDate()}</Moment></h4></div>
            </div>
          </div>
              
                   <div  className={come ? styles.paperz : styles.paperz2} >
       <div className={styles.sure}>
          <h4>Yooo slow down!!</h4>
           <h3>Are u sure u wanna Delete?</h3>
           <div className={styles.buttns}>
              <button className={styles.nope} onClick={() => {
                setCome(false);
              }} >No</button>
              <button className={styles.yap} onClick={() =>  {
                   deleteDoc(doc(db, "Kununua", postId))
                   .then(() => {
                    console.log("document deleted succesfully")
                   })
                   .catch((error) => {
                    console.log("error deleting document", error)
                   })
                   setCome(false)
              }} >Yes</button>
           
           </div>
       </div>
                  </div>
                  <div className={ dar.id === inde ? styles.anonymous : styles.anonymous2} >
                    <div className={styles.clos} onClick={() => setInde("")} ><Close className={classes.iconp} /></div>
             <h4>Badilisha Kiasi cha kodi</h4>
                          
                <input className={styles.inputz} type='number' value={kodiz} onChange={(e) => setKodiz(e.target.value)} placeholder={dar.data()?.kodi} />
                <button className={styles.badilishaz} onClick={() => sendKodi2(dar.id)} >badilisha</button>
          </div>

          </div>
          
       ))}
        

       
    </div>
  )
}

export default Profile