import React from 'react'
import styles from "../styles/duka.module.css"
import ParanomaG1 from './ParanomaG1'
import ParanomaG2 from './ParanomaG2'
import ParanomaG3 from './ParanomaG3'
import ParanomaG4 from './ParanomaG4'
import ParanomaG5 from './ParanomaG5'

function Godown() {
  return (
    <div className={styles.duka}>
        <ParanomaG1/>
        <ParanomaG2/>
        <ParanomaG3/>
        <ParanomaG4/>
        <ParanomaG5/>
    </div>
  )
}

export default Godown