import React, { useState } from 'react'
import styles from "../styles/bill.module.css"
import Carusel from './Carusel'
import CascadingOptions from './CascadingOptions'
import Body from './Body'
import { Link } from "react-router-dom"
import Body2 from './Body2'
import Kupangisha from './Kupangisha'
import Kuuza from './Kuuza'
import Tafuta from './Tafuta'
import { useStateValue } from '../StateProvider'
import { actionTypes } from "../reducer";
import { Modal } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { signInWithPopup } from 'firebase/auth';
import { auth, provider } from '../firebase';
import { useNavigation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import NightShelterIcon from "@mui/icons-material/NightShelter";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService"
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage"
import AddHome from "@mui/icons-material/AddHome"




function getModalStyle() {

 
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}% )`
  }
}


const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "black",
    position: 'absolute',
    top: "25vh",
    left: "15vw",
    width: "30%",
    height: "fit-content",
    border: '2px solid blue',
    [theme.breakpoints.down('sm')]:{
      width: "70%",
      left: "15%",
    }
    
  },
  
}))




function Billboard() {
    
    const [select, setSelect] = useState(0)
    const [select2, setSelect2] = useState(select)
    const [smkoa, setSmkoa] = useState("")
    const [swilaya, setSwilaya] = useState("")
    const [skata, setSkata] = useState("")
    const [open2, setOpen2] = useState(false)
    const [cat, setCat] = useState("Kupangisha")
    const [cat2, setCat2] = useState("Kununua")
    const [{user}, dispatch] = useStateValue(); 
    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const navigate = useNavigate()
    
  
    const divs = [
        { id: 0, label: "KUPANGA", link: "/page"},
        { id: 1, label: "KUNUNUA", link: "/pag2"},
        { id: 2, label: "KUPANGISHA", link: "/pag3"},
        { id: 3, label: "KUUZA", link: "/pag4"},        
    ]
    const divClicked = (index) => {
          setSelect(index)
    }

    const trial = () => {
       if(smkoa === ""){
        alert("haujachagua mkoa")
       }else if(swilaya === ""){
        alert("haujachagua Jimbo")
       }else if(skata === ""){
        alert("haujachagua kata")
       }else{
        dispatch({
          type: actionTypes.SET_MKOA,
          payload: smkoa,
         })
         dispatch({
          type: actionTypes.SET_JIMBO,
          payload: swilaya,
         })
         dispatch({
          type: actionTypes.SET_KATA,
          payload: skata,
         })

        if(select == 0){
          console.log("value of select2", select2)
          dispatch({
            type: actionTypes.SET_CATZ,
            payload: cat,
           })
          navigate("/tafuta")
        }else if(select == 1){
          
          dispatch({
            type: actionTypes.SET_CATZ,
            payload: cat2,
           })
          navigate("/tafuta")
        }else{
          alert("Chagua kati ya Kupanga au Kununua kwanza")
        }
        
       }
    }

        function SignIn(){
      signInWithPopup(auth,provider)
      .then(result => {
          dispatch({
              type: actionTypes.SET_USER,
              user: result.user,
          })
          
      }).catch(error => alert(error.message))
    }

    
    

  return (
    <div className={styles.bill} >
      <head>
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9205716940827637"
     crossorigin="anonymous"></script>
      </head>
      <Modal
  open={open2}
  onClose={(e) => {
    e.stopPropagation();
    setOpen2(false);
  }}
  >
  <div style={modalStyle} className={classes.paper} onClick={(e)=> {
    e.stopPropagation();
  }}>
     <h2 className={styles.karibu} >Karibu Zero Dalali</h2>
     <h4 className={styles.binya} >bonyeza Ingia ili uweze kuendelea mbele</h4>
      <button className={styles.ingia} onClick={(e)=> {
                                    e.stopPropagation();
                                    setOpen2(false);
                                    SignIn()
                                }} >Ingia</button>
  </div>
</Modal>
             <Carusel/>
      <CascadingOptions trial={trial} setSmkoa={setSmkoa} setSwilaya= {setSwilaya} setSkata={setSkata} />
      
   
      <div className={styles.nav}>
         {divs.map((div) => (
            <div className={styles.cover}  onClick={() => divClicked(div.id)}
             >
              {div.id === 0 && (<div className={styles.topz} style={{color: select === div.id ? "red" : "white"}} > <NightShelterIcon fontSize='inherit' /></div>) }
              {div.id === 1 &&  (<div className={styles.topz} style={{color: select === div.id ? "red" : "white"}}  > <AddHome fontSize='inherit' /></div>)}
              {div.id === 2 && (<div className={styles.topz} style={{color: select === div.id ? "red" : "white"}} ><HolidayVillageIcon fontSize='inherit' /></div>) }
              {div.id === 3 &&  (<div className={styles.topz} style={{color: select === div.id ? "red" : "white"}} ><HomeRepairServiceIcon fontSize='inherit' /></div>) }             
              
              <h2 className={styles.nax} 
                  style={{
                    borderBottom: select === div.id ? "0.5vh solid red" : "none",
                    cursor: "pointer",
                 }}                      
              >
                {div.label}
              </h2>
              </div>
         ))}
         
         
      </div>
      {select === 0 && <Body/>}
      {select === 1 && <Body2/>}
      {select === 2 && <Kupangisha/>  }
      {select === 3 &&  <Kuuza/>  }
       
    </div>
  )
}

export default Billboard       