import React, { useState } from 'react'
import styles from "../styles/chats.module.css"
import test from "../images/house.png"
import { useStateValue } from '../StateProvider'

function Chats() { 
  
  
  return (
    <div className={styles.chats} >
        <div className={styles.chatlist} >
            <div className={styles.intro} >
                <h2>Z-Chats</h2>
            </div>
            <div className={styles.list} >
                <div className={styles.bar} >
                <img src={test} alt='' className={styles.imeji} />
                <div className={styles.mido} >
                    <h2>Deogratius Gama</h2>
                    <p>Ile nyumba vipi tunaiuza kwa shingapi pale kati</p>
                </div>
                <div className={styles.last} >
                     <h2 className={styles.time} >13:35</h2>
                     <h3 className={styles.num} >2</h3>
                </div>
                </div>

            </div>

        </div>
        <div className={styles.realchat} >
       
       <div className={styles.tops} >
        <div className={styles.realtop} >
       <img src={test} alt='' className={styles.imeji2} /><h2>Deogratius Gama</h2>
       </div>
       <div className={styles.online} >
         <div className={styles.doti} ></div> <h3 className={styles.line} >Offline</h3>
         </div>
       </div>

       <div className={styles.lefts} >
        <div className={styles.bubble1} >
           <h2>yooo this is the first chat</h2>
           <h3>16:15</h3>
        </div>
       </div>

       <div className={styles.rights} >
        <div className={styles.bubble2} >
           <h2>yooo this is the first chat</h2>
           <h3>16:15</h3>
        </div>
       </div>

        </div>



    </div>
  )
}

export default Chats