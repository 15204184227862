import React from 'react'
import styles from "../styles/duka.module.css"
import ParanomaD1 from './ParanomaD1'
import ParanomaD2 from './ParanomaD2'
import ParanomaD3 from './ParanomaD3'
import ParanomaD4 from './ParanomaD4'
import ParanomaD5 from './ParanomaD5'

function Duka() {
  return (
    <div className={styles.duka} >
        <ParanomaD1/>
        <ParanomaD2/>
        <ParanomaD3/>
        <ParanomaD4/>
        <ParanomaD5/>
    </div>
  )
}

export default Duka