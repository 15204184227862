import React, {useEffect} from 'react'
import "./styles/App.css"
import Head from './components/Head';
import Billboard from './components/Billboard';
import Body from './components/Body';
import Footer from './components/Footer';
import Maps from './components/Maps';
import Trial from './components/Trial';
import Duka from './components/Duka';
import { BrowserRouter, Routes, Router, Route } from 'react-router-dom';
import Home from './pages/Home';
import Single from './pages/Single';
import Adverts from './pages/Adverts';
import { useStateValue } from './StateProvider';

import Aboutz from './pages/Aboutz';
import Search from './pages/Search';
import Chats from './pages/Chats';
import { useState } from 'react';
import { saveMessagingDeviceToken } from './messaging';
import {requestPermissionNow} from "./messaging.js"

 function App() {
  const [{user}] = useStateValue()  

 
  

  useEffect(()=> {
    requestPermissionNow()
  }, [])

  
   
  return (
    <div className="App"  >

      <BrowserRouter>
      <Routes>
        
          <Route path="/" element={<Home/>} />

          <Route path="/single/:id" element={<Single/>} />
          <Route path="/adverts" element={<Adverts/>} />
          <Route path="/about" element={<Aboutz/>} />
          <Route path="/tafuta" element={<Search/>} />
          <Route path="/chats" element={<Chats/>} />
          
      </Routes>
     </BrowserRouter>       
    </div>
  )
}

export default App